import axios from "axios";

export async function getTemplates() {
  try {
    // Send request
    const res = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/template`,
      { withCredentials: true }
    );
    return {
      isOk: true,
      data: res.data.templates,
    };
  } catch {
    return {
      isOk: false,
      message: "Failed to fetch templates",
    };
  }
}

export async function addTemplate(template: any) {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/template`,
      template,
      { withCredentials: true }
    );
    return {
      isOk: true,
      data: res.data.template,
    };
  } catch {
    return {
      isOk: false,
      message: "Failed to add template",
    };
  }
}

export async function updateTemplate(template: any) {
  try {
    const res = await axios.put(
      `${process.env.REACT_APP_SERVER_URL}/template/${template.id}`,
      template,
      { withCredentials: true }
    );
    return {
      isOk: true,
      data: res.data.template,
    };
  } catch {
    return {
      isOk: false,
      message: "Failed to update template",
    };
  }
}

export async function deleteTemplate(templateId: string) {
  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_SERVER_URL}/template/${templateId}`,
      { withCredentials: true }
    );
    return {
      isOk: true,
      data: res.data.noOfRows,
    };
  } catch {
    return {
      isOk: false,
      message: "Failed to delete template",
    };
  }
}