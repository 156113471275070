import { EmailAddresses, EmailClient, Admin, EventCalendar, TemplateDashboard, TemplateEditor } from './pages';
import { withNavigationWatcher } from './contexts/navigation';

const routes = [
  {
    path: '/email-addresses',
    element: EmailAddresses,
  },
  {
    path: '/email-client',
    element: EmailClient,
  },
  {
    path: '/admin',
    element: Admin,
  },
  {
    path: '/calendar',
    element: EventCalendar,
  },
  {
    path: '/templates',
    element: TemplateDashboard,
  },
  {
    path: 'template-editor',
    element: TemplateEditor,
  }
];

export const appRoutes = routes.map((route) => {
  return {
    ...route,
    element: withNavigationWatcher(route.element, route.path),
  };
});
