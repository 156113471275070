import React from "react";
import managementData from "../data/managementSection.json";

interface ManagementBlock {
  id: number;
  image: string;
  title: string;
  description: string;
}

const Management: React.FC = () => {
  return (
    <section className="management">
      <div className="container-management">
        <div className="content-management">
          {managementData.blocks.map((management: ManagementBlock) => (
            <div className="item" key={management.id.toString()}>
              <div className="left-side">
                <img src={"../management/mockup.png"} alt="Mockup" width={550} height={350} />
              </div>
              <div className="right-side">
                <h3>{management.title}</h3>
                <p>{management.description}</p>
                {/* <button>Learn more</button> */}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Management;