import axios from 'axios';

export async function getCreditInfo() {
  try {
    // Send request
    const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/credit`, { withCredentials: true });
    return {
      isOk: true,
      data: response.data.credit,
    };
  } catch {
    return {
      isOk: false,
      message: 'Failed to fetch Credit Info',
    };
  }
}