import { createSlice } from '@reduxjs/toolkit'

export interface TrustedEmailAddressState {
  id: string,
  emailAddress: string,
  createdAt: string,
  updatedAt: string,
  userId: string,
}

const initialState: TrustedEmailAddressState[] = [];

export const trustedEmailAddressesSlice = createSlice({
  name: 'trustedEmailAddresses',
  initialState,
  reducers: {
    setTrustedEmailAddresses: (state, action) => {
      return action.payload.trustedEmailAddresses;
    },
    addTrustedEmailAddress: (state, action) => {
      state.push(action.payload.trustedEmailAddress)
    },
    deleteTrustedEmailAddress: (state, action) => {
      return state.filter(emailAddress => emailAddress.id !== action.payload.id);
    }
  },
})

// Action creators are generated for each case reducer function
export const { setTrustedEmailAddresses, addTrustedEmailAddress, deleteTrustedEmailAddress } = trustedEmailAddressesSlice.actions

export default trustedEmailAddressesSlice.reducer