import React, { useEffect, useMemo, useRef, useState } from "react";
import "./email-client.css"; // Import the CSS file for styling
import { Popup, TagBox, TextArea } from "devextreme-react";
import { useLocation } from "react-router-dom";
import { sendEmail } from "../../api/email";
import { toast } from "react-toastify";
import EmailGeneratorPopupV2 from "./email-generator-popup-v2";
import JoditEditor from "jodit-react";
import { TemplateDashboard } from "../templates/template-dashboard";

export const EmailClient = () => {
  const joditRef = useRef(null);
  const [toEmails, setToEmails] = useState<any[]>([]);
  const [publicEvent, setPublicEvent] = useState<object>({});
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [sendingEmail, setSendingEmail] = useState(false);
  const [showEmailGenerator, setShowEmailGenerator] = useState(false);
  const [showTemplateChooser, setShowTemplateChooser] = useState(false);

  const location = useLocation();
  const emailContent = location.state as any;
  const toEmailAddresses = emailContent?.toEmails;
  const eventFromCalendar = emailContent?.publicEvent;
  const emailTemplate = emailContent?.emailTemplate;

  const handleToEmailsChanged = (e: any) => {
    if (e.event) setToEmails(e.value);
  };

  const handleSubjectChange = (e: any) => {
    setSubject(e.target.value);
  };

  const handleSendEmail = async (sendAsGroup: boolean) => {
    setSendingEmail(true);
    sendEmail({
      recipients: toEmails,
      subject,
      body,
      sendAsGroup,
    }).then((res) => {
      if (res.isOk) {
        toast.success("Email Sent!");
      } else {
        toast.error(res.message);
      }
    });
    setSendingEmail(false);
  };

  useEffect(() => {
    setToEmails(toEmailAddresses);
    if (eventFromCalendar) {
      setPublicEvent(eventFromCalendar);
      setSubject(eventFromCalendar.title);
    }
    if (emailTemplate) {
      setSubject(emailTemplate.emailSubject);
      setBody(emailTemplate.emailBody);
    }
  }, []);

  const joditConfig = useMemo(() => {
    return {
      iframe: true,
      disablePlugins: ["powered-by-jodit"],
      activeButtonsInReadOnly: ["cut", "copy", "paste", "selectall"],
      history: {
        enable: true,
      },
      buttons: [
        {
          text: "🖉 Write with AI",
          name: "Write with AI",
          exec: () => setShowEmailGenerator(true),
          // icon: "pencil",
          tooltip: "Write with AI",
        },
        "|",
        {
          text: "🖉 Select Template",
          name: "Select Template",
          exec: () => setShowTemplateChooser(true),
          // icon: "pencil",
          tooltip: "Select Template",
        },
        "|",
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "|",
        "ul",
        "ol",
        "|",
        "font",
        "fontsize",
        "paragraph",
        "classSpan",
        "brush",
        "|",
        "lineHeight",
        "indent",
        "outdent",
        "left",
        "center",
        "right",
        "|",
        "superscript",
        "subscript",
        "\n",
        "cut",
        "copy",
        "paste",
        "selectall",
        "|",
        "copyformat",
        "eraser",
        "|",
        "hr",
        "table",
        "link",
        "symbols",
        "file",
        "image",
        // "spellcheck",
        "|",
        "undo",
        "redo",
        "|",
        "source",
        // "fullsize",
        "preview",
        // "print",
      ],
    };
  }, []);

  return (
    <div className="email-client">
      <p className="email-client__title">Send Your Email!</p>
      <div className="email-client__field">
        <label htmlFor="to">To:</label>
        <TagBox
          dataSource={toEmailAddresses}
          value={toEmails}
          searchEnabled
          acceptCustomValue
          multiline={false}
          hideSelectedItems
          onValueChanged={handleToEmailsChanged}
          className="email-client__tagbox"
        />
      </div>
      <div className="email-client__field">
        <label htmlFor="subject">Subject:</label>
        <input
          type="text"
          id="subject"
          value={subject}
          onChange={handleSubjectChange}
          className="email-client__input"
        />
      </div>
      <div className="email-client__field">
        <label htmlFor="body">Body:</label>
        <JoditEditor
          ref={joditRef}
          value={body}
          config={joditConfig}
          onBlur={(newContent) => {
            setBody(newContent);
          }}
        />
      </div>
      <div className="email-client__buttons">
        <button
          disabled={sendingEmail}
          className="email-client__button"
          onClick={(e) => handleSendEmail(false)}
        >
          Send email individually
        </button>
        <button
          disabled={sendingEmail}
          className="email-client__button"
          onClick={(e) => handleSendEmail(true)}
        >
          Send email in batch
        </button>
      </div>
      {showEmailGenerator && (
        <Popup
          visible={showEmailGenerator}
          onHiding={() => setShowEmailGenerator(false)}
          dragEnabled={false}
          hideOnOutsideClick={false}
          showCloseButton={true}
          showTitle={true}
          title="Email Generator"
          container=".dx-viewport"
        >
          <EmailGeneratorPopupV2
            callback={(emailText: string) => {
              setShowEmailGenerator(false);
              setBody(emailText);
            }}
            emailAddressList={toEmails}
            subject={subject}
            publicEvent={publicEvent}
          />
        </Popup>
      )}
      {showTemplateChooser && (
        <Popup
          visible={showTemplateChooser}
          onHiding={() => setShowTemplateChooser(false)}
          dragEnabled={false}
          hideOnOutsideClick={true}
          showCloseButton={true}
          container=".dx-viewport"
          height="80%"
        >
          <TemplateDashboard
            selectOnly
            callbackOnSelection={(data) => {
              setShowTemplateChooser(false);
              setBody(data.emailBody);
              setSubject(data.emailSubject);
            }}
          />
        </Popup>
      )}
    </div>
  );
};
