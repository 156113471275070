import React, { useMemo, useState } from "react";
import { Template } from "./template-dashboard";
import {
  Form,
  SimpleItem,
  Label,
  GroupItem,
  ButtonItem,
} from "devextreme-react/form";
import "./template-editor.css";
import JoditEditor from "jodit-react";
import { Popup } from "devextreme-react";
import EmailGeneratorPopupV2 from "../email-client/email-generator-popup-v2";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { addTemplate, updateTemplate } from "../../api/template";

// interface TemplateEditorProps {
//   mode: "edit" | "add";
//   template?: Template | null;
//   callback: any;
//   height?: number | string;
// }

export const TemplateEditor = () => {
  const [loading, setLoading] = useState(false);
  const [showEmailGenerator, setShowEmailGenerator] = useState(false);
  const joditConfig = useMemo(() => {
    return {
      iframe: true,
      disablePlugins: ["powered-by-jodit"],
      activeButtonsInReadOnly: ["cut", "copy", "paste", "selectall"],
      history: {
        enable: true,
      },
      buttons: [
        {
          text: "🖉 Write with AI",
          name: "Write with AI",
          exec: () => setShowEmailGenerator(true),
          // icon: "pencil",
          tooltip: "Write with AI",
        },
        "|",
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "|",
        "ul",
        "ol",
        "|",
        "font",
        "fontsize",
        "paragraph",
        "classSpan",
        "brush",
        "|",
        "lineHeight",
        "indent",
        "outdent",
        "left",
        "center",
        "right",
        "|",
        "superscript",
        "subscript",
        "\n",
        "cut",
        "copy",
        "paste",
        "selectall",
        "|",
        "copyformat",
        "eraser",
        "|",
        "hr",
        "table",
        "link",
        "symbols",
        "file",
        "image",
        // "spellcheck",
        "|",
        "undo",
        "redo",
        "|",
        "source",
        // "fullsize",
        "preview",
        // "print",
      ],
    };
  }, []);
  const navigate = useNavigate();
  const location = useLocation();
  const editorContent = location.state as any;

  const [formData, setFormData] = useState<Template>(
    editorContent && editorContent.mode === "edit" && editorContent.template
      ? editorContent.template
      : {
          id: "",
          name: "",
          description: "",
          emailSubject: "",
          emailBody: "",
          isPublic: false,
        }
  );

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!editorContent) {
      toast.error(
        "Missing template editor content; Route to template editor through template dashboard."
      );
    }
    console.log(formData);
    if (formData.id === "") {
      onAddTemplate(formData);
    } else {
      onUpdateTemplate(formData);
    }
  };

  const onUpdateTemplate = (template: Template) => {
    setLoading(true);
    updateTemplate(template)
      .then((res) => {
        if (res.isOk) {
          toast.success("Template updated successfully");
          navigate("/templates");
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
    setLoading(false);
  };

  const onAddTemplate = (template: Template) => {
    setLoading(true);
    addTemplate(template)
      .then((res) => {
        if (res.isOk) {
          toast.success("Template added successfully");
          navigate("/templates");
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
    setLoading(false);
  };

  return (
    <div className="template-editor">
      <p className="template-editor__title">Template Editor</p>
      <form onSubmit={handleSubmit}>
        <Form
          formData={formData}
          id="template-editor-form"
          labelMode="floating"
          labelLocation="left"
          showColonAfterLabel
          showRequiredMark
        >
          <GroupItem colCount={6}>
            <SimpleItem colSpan={3} isRequired={true} dataField="name" />
            <SimpleItem
              colSpan={3}
              isRequired={true}
              dataField="emailSubject"
            />
          </GroupItem>
          <GroupItem colCount={6}>
            <SimpleItem
              colSpan={4}
              isRequired={true}
              dataField="description"
              editorType="dxTextArea"
            />
            <SimpleItem
              colSpan={2}
              isRequired={false}
              dataField="isPublic"
              editorType="dxSwitch"
              helpText="Public templates can be used by all users. Please use this option carefully."
            />
          </GroupItem>
          <SimpleItem isRequired={true} dataField="emailBody">
            <Label text="Email Body" />
            <JoditEditor
              value={formData.emailBody}
              config={joditConfig}
              onBlur={(newContent) => {
                setFormData({ ...formData, emailBody: newContent });
              }}
            />
          </SimpleItem>
          <ButtonItem
            horizontalAlignment="center"
            buttonOptions={{
              text: "Save",
              type: "default",
              useSubmitBehavior: true,
              disabled: loading,
            }}
          />
        </Form>
      </form>
      {showEmailGenerator && (
        <Popup
          visible={showEmailGenerator}
          onHiding={() => setShowEmailGenerator(false)}
          dragEnabled={false}
          hideOnOutsideClick={false}
          showCloseButton={true}
          showTitle={true}
          title="Email Generator"
          container=".dx-viewport"
        >
          <EmailGeneratorPopupV2
            callback={(emailText: string) => {
              setShowEmailGenerator(false);
              setFormData({ ...formData, emailBody: emailText });
            }}
          />
        </Popup>
      )}
    </div>
  );
};
