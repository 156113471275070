import React, { useEffect, useState } from "react";


import { getProfile } from "../../api/profile";

import type { UserPanelProps } from "../../types";

import "./UserPanel.scss";
import { DropDownButton } from "devextreme-react";
import { confirm } from "devextreme/ui/dialog";
import { logout } from "../../api/auth";

export const UserPanel = ({ menuMode }: UserPanelProps) => {
  const [profile, setProfile] = useState<any>();

  useEffect(() => {
    getProfile().then((res) => {
      if (res.isOk) {
        setProfile(res.data?.data.profile);
      }
    });
  }, []);

  return (
    <div className="user-panel">
      {/* <div className="user-info">
        <div className="image-container">
          <div
            style={{
              backgroundSize: "cover",
            }}
            className="user-image"
          >
            {profile?.userImage && (
              <img src={profile?.userImage} alt="User avatar" height="25px" />
            )}
          </div>
        </div>
        <div className="user-name">{profile?.username}</div>
      </div> */}
      <DropDownButton 
        stylingMode="text"
        text={profile?.username}
        icon={profile?.userImage ? profile?.userImage : 'user'}
        displayExpr="text"
        items={[
          { id: 0, text: 'Logout', icon: 'key' }
        ]}
        onItemClick={(e) => {
          if (e.itemData.id === 0) {
            let confirmation = confirm('Are you sure you want to logout?', 'Logout');
            confirmation.then((res) => {
              if(res){
                logout().then((res) => {
                      if (res.url) {
                        window.location.replace(res.url);
                      }
                    })
                  }
            });
          }
        }}
      />
    </div>
  );
};
