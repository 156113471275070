import { Task } from '../types/task';
import { Contact } from '../types/crm-contact';

export const PRIORITY_ITEMS = ['Low', 'Normal', 'High'];
export const STATUS_ITEMS = ['Open', 'In Progress', 'Deferred', 'Completed'];

export const ANALYTICS_PERIODS = {
  Week: {
    period: '2020-01-24/2020-01-31',
    index: 0,
  },
  '2 Weeks': {
    period: '2020-01-14/2020-01-31',
    index: 1,
  },
  Month: {
    period: '2020-01-01/2020-02-01',
    index: 2,
  },
  Year: {
    period: '2020-01-01/2021-01-01',
    index: 3,
  },
  All: {
    period: '2018-01-01/2022-01-01',
    index: 4,
  },
};

export const DEFAULT_ANALYTICS_PERIOD_KEY = 'All';

export const CONTACT_STATUS_LIST = [
  'Salaried',
  'Commission',
  'Terminated',
];

export const newTask: Task = {
  id: 0,
  text: '',
  description: '',
  company: '',
  priority: 'Low',
  startDate: new Date(),
  dueDate: new Date(),
  owner: '',
  status: 'Open',
  activities: [],
  notes: [],
  messages: [],
  parentId: 0,
  progress: 0,
};

export const newContact: Contact = {
  id: 0,
  name: '',
  address: '',
  firstName: '',
  lastName: '',
  status: 'Salaried',
  position: '',
  manager: '',
  company: '',
  city: '',
  state: {
    stateShort: '',
  },
  zipCode: 0,
  phone: '',
  email: '',
  image: '',
  activities: [],
  opportunities: [],
  tasks: [],
};

export const raabtaScoreColors = {
  hot: 'red',
  warm: 'plum',
  cold: 'blue',
}

export const calendarColors = {
  past: "#EDEDED",
  current: "#F08D7E",
  nearfuture: "#3E88A5",
  future: "#93C4D1",
};

export const specialCharacterFormat = /[ `!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?~]/;

export const Google_Calender_Country_Mapping = [
  { caption: "Christian Holidays", code: "christian" },
  { caption: "Jewish Holidays", code: "judaism" },
  { caption: "Muslim Holidays", code: "islamic" },
  { caption: "Orthodox Holidays", code: "orthodox_christianity" },
  { caption: "Afghanistan", code: "af" },
  { caption: "Albania", code: "al" },
  { caption: "Algeria", code: "dz" },
  { caption: "American Samoa", code: "as" },
  { caption: "Andorra", code: "ad" },
  { caption: "Angola", code: "ao" },
  { caption: "Anguilla", code: "ai" },
  { caption: "Antigua and Barbuda", code: "ag" },
  { caption: "Argentina", code: "ar" },
  { caption: "Armenia", code: "am" },
  { caption: "Aruba", code: "aw" },
  { caption: "Australia", code: "australian" },
  { caption: "Austria", code: "austrian" },
  { caption: "Azerbaijan", code: "az" },
  { caption: "Bahamas", code: "bs" },
  { caption: "Bahrain", code: "bh" },
  { caption: "Bangladesh", code: "bd" },
  { caption: "Barbados", code: "bb" },
  { caption: "Belarus", code: "by" },
  { caption: "Belgium", code: "be" },
  { caption: "Belize", code: "bz" },
  { caption: "Benin", code: "bj" },
  { caption: "Bermuda", code: "bm" },
  { caption: "Bhutan", code: "bt" },
  { caption: "Bolivia", code: "bo" },
  { caption: "Bosnia and Herzegovina", code: "ba" },
  { caption: "Botswana", code: "bw" },
  { caption: "Brazil", code: "brazilian" },
  { caption: "British Virgin Islands", code: "vg" },
  { caption: "Brunei Darussalam", code: "bn" },
  { caption: "Bulgaria", code: "bulgarian" },
  { caption: "Burkina Faso", code: "bf" },
  { caption: "Burundi", code: "bi" },
  { caption: "Cambodia", code: "kh" },
  { caption: "Cameroon", code: "cm" },
  { caption: "Canada", code: "canadian" },
  { caption: "Cape Verde", code: "cv" },
  { caption: "Cayman Islands", code: "ky" },
  { caption: "Central African Republic", code: "cf" },
  { caption: "Chad", code: "td" },
  { caption: "Chile", code: "cl" },
  { caption: "China", code: "china" },
  { caption: "Colombia", code: "co" },
  { caption: "Comoros", code: "km" },
  { caption: "Congo", code: "cg" },
  { caption: "Cook Islands", code: "ck" },
  { caption: "Costa Rica", code: "cr" },
  { caption: "Côte d'Ivoire", code: "ci" },
  { caption: "Croatia", code: "croatian" },
  { caption: "Cuba", code: "cu" },
  { caption: "Curaçao", code: "cw" },
  { caption: "Cyprus", code: "cy" },
  { caption: "Czechia", code: "czech" },
  { caption: "Democratic People's Republic of Korea", code: "kp" },
  { caption: "Denmark", code: "danish" },
  { caption: "Djibouti", code: "dj" },
  { caption: "Dominica", code: "dm" },
  { caption: "Dominican Republic", code: "do" },
  { caption: "Ecuador", code: "ec" },
  { caption: "Egypt", code: "eg" },
  { caption: "El Salvador", code: "sv" },
  { caption: "Equatorial Guinea", code: "gq" },
  { caption: "Eritrea", code: "er" },
  { caption: "Estonia", code: "ee" },
  { caption: "Ethiopia", code: "et" },
  { caption: "Falkland Islands (Malvinas)", code: "fk" },
  { caption: "Faroe Islands", code: "fo" },
  { caption: "Federated States of Micronesia", code: "fm" },
  { caption: "Fiji", code: "fj" },
  { caption: "Finland", code: "finnish" },
  { caption: "France", code: "french" },
  { caption: "French Guiana", code: "gf" },
  { caption: "French Polynesia", code: "pf" },
  { caption: "Gabon", code: "ga" },
  { caption: "Gambia", code: "gm" },
  { caption: "Georgia", code: "ge" },
  { caption: "Germany", code: "german" },
  { caption: "Ghana", code: "gh" },
  { caption: "Gibraltar", code: "gi" },
  { caption: "Greece", code: "greek" },
  { caption: "Greenland", code: "gl" },
  { caption: "Grenada", code: "gd" },
  { caption: "Guam", code: "gu" },
  { caption: "Guatemala", code: "gt" },
  { caption: "Guernsey", code: "gg" },
  { caption: "Guinea", code: "gn" },
  { caption: "Guinea-Bissau", code: "gw" },
  { caption: "Guyana", code: "gy" },
  { caption: "Haiti", code: "ht" },
  { caption: "Holy See (Vatican City State)", code: "va" },
  { caption: "Honduras", code: "hn" },
  { caption: "Hong Kong", code: "hong_kong" },
  { caption: "Hungary", code: "hungarian" },
  { caption: "Iceland", code: "is" },
  { caption: "India", code: "indian" },
  { caption: "Indonesia", code: "indonesian" },
  { caption: "Iraq", code: "iq" },
  { caption: "Ireland", code: "irish" },
  { caption: "Islamic Republic of Iran", code: "ir" },
  { caption: "Isle of Man", code: "im" },
  { caption: "Israel", code: "jewish" },
  { caption: "Italy", code: "italian" },
  { caption: "Jamaica", code: "jm" },
  { caption: "Japan", code: "japanese" },
  { caption: "Jersey", code: "je" },
  { caption: "Jordan", code: "jo" },
  { caption: "Kazakhstan", code: "kz" },
  { caption: "Kenya", code: "ke" },
  { caption: "Kiribati", code: "ki" },
  { caption: "Kuwait", code: "kw" },
  { caption: "Kyrgyzstan", code: "kg" },
  { caption: "Lao People's Democratic Republic", code: "la" },
  { caption: "Latvia", code: "latvian" },
  { caption: "Lebanon", code: "lb" },
  { caption: "Lesotho", code: "ls" },
  { caption: "Liberia", code: "lr" },
  { caption: "Libya", code: "ly" },
  { caption: "Liechtenstein", code: "li" },
  { caption: "Lithuania", code: "lithuanian" },
  { caption: "Luxembourg", code: "lu" },
  { caption: "Macao", code: "mo" },
  { caption: "Madagascar", code: "mg" },
  { caption: "Malawi", code: "mw" },
  { caption: "Malaysia", code: "malaysia" },
  { caption: "Maldives", code: "mv" },
  { caption: "Mali", code: "ml" },
  { caption: "Malta", code: "mt" },
  { caption: "Marshall Islands", code: "mh" },
  { caption: "Martinique", code: "mq" },
  { caption: "Mauritania", code: "mr" },
  { caption: "Mauritius", code: "mu" },
  { caption: "Mayotte", code: "yt" },
  { caption: "Mexico", code: "mexican" },
  { caption: "Moldova", code: "md" },
  { caption: "Monaco", code: "mc" },
  { caption: "Mongolia", code: "mn" },
  { caption: "Montenegro", code: "me" },
  { caption: "Montserrat", code: "ms" },
  { caption: "Morocco", code: "ma" },
  { caption: "Mozambique", code: "mz" },
  { caption: "Myanmar", code: "mm" },
  { caption: "Namibia", code: "na" },
  { caption: "Nauru", code: "nr" },
  { caption: "Nepal", code: "np" },
  { caption: "Netherlands", code: "dutch" },
  { caption: "New Caledonia", code: "nc" },
  { caption: "New Zealand", code: "new_zealand" },
  { caption: "Nicaragua", code: "ni" },
  { caption: "Niger", code: "ne" },
  { caption: "Nigeria", code: "ng" },
  { caption: "Northern Mariana Islands", code: "mp" },
  { caption: "Norway", code: "norwegian" },
  { caption: "Oman", code: "om" },
  { caption: "Pakistan", code: "pk" },
  { caption: "Palau", code: "pw" },
  { caption: "Panama", code: "pa" },
  { caption: "Papua New Guinea", code: "pg" },
  { caption: "Paraguay", code: "py" },
  { caption: "Peru", code: "pe" },
  { caption: "Philippines", code: "philippines" },
  { caption: "Poland", code: "polish" },
  { caption: "Portugal", code: "portuguese" },
  { caption: "Puerto Rico", code: "pr" },
  { caption: "Qatar", code: "qa" },
  { caption: "Republic of Korea", code: "south_korea" },
  { caption: "Réunion", code: "re" },
  { caption: "Romania", code: "romanian" },
  { caption: "Russian Federation", code: "russian" },
  { caption: "Rwanda", code: "rw" },
  { caption: "Saint Barthélemy", code: "bl" },
  { caption: "Saint Helena", code: "sh" },
  { caption: "Saint Kitts and Nevis", code: "kn" },
  { caption: "Saint Lucia", code: "lc" },
  { caption: "Saint Martin (French part)", code: "mf" },
  { caption: "Saint Pierre and Miquelon", code: "pm" },
  { caption: "Saint Vincent and the Grenadines", code: "vc" },
  { caption: "Samoa", code: "ws" },
  { caption: "San Marino", code: "sm" },
  { caption: "Sao Tome and Principe", code: "st" },
  { caption: "Saudi Arabia", code: "saudiarabian" },
  { caption: "Senegal", code: "sn" },
  { caption: "Serbia", code: "rs" },
  { caption: "Seychelles", code: "sc" },
  { caption: "Sierra Leone", code: "sl" },
  { caption: "Singapore", code: "singapore" },
  { caption: "Sint Maarten (Dutch part)", code: "sx" },
  { caption: "Slovakia", code: "slovak" },
  { caption: "Slovenia", code: "slovenian" },
  { caption: "Solomon Islands", code: "sb" },
  { caption: "Somalia", code: "so" },
  { caption: "South Africa", code: "sa" },
  { caption: "South Sudan", code: "ss" },
  { caption: "Spain", code: "spain" },
  { caption: "Sri Lanka", code: "lk" },
  { caption: "Sudan", code: "sd" },
  { caption: "Suriname", code: "sr" },
  { caption: "Swaziland", code: "sz" },
  { caption: "Sweden", code: "swedish" },
  { caption: "Switzerland", code: "ch" },
  { caption: "Syrian Arab Republic", code: "sy" },
  { caption: "Taiwan", code: "taiwan" },
  { caption: "Tajikistan", code: "tj" },
  { caption: "Thailand", code: "th" },
  { caption: "The Democratic Republic of the Congo", code: "cd" },
  { caption: "The Former Yugoslav Republic of Macedonia", code: "mk" },
  { caption: "Timor-Leste", code: "tl" },
  { caption: "Togo", code: "tg" },
  { caption: "Tonga", code: "to" },
  { caption: "Trinidad and Tobago", code: "tt" },
  { caption: "Tunisia", code: "tn" },
  { caption: "Turkey", code: "turkish" },
  { caption: "Turkmenistan", code: "tm" },
  { caption: "Turks and Caicos Islands", code: "tc" },
  { caption: "Tuvalu", code: "tv" },
  { caption: "U.S. Virgin Islands", code: "vi" },
  { caption: "Uganda", code: "ug" },
  { caption: "Ukraine", code: "ukrainian" },
  { caption: "United Arab Emirates", code: "ae" },
  { caption: "United Kingdom", code: "uk" },
  { caption: "United Republic of Tanzania", code: "tz" },
  { caption: "United States", code: "usa" },
  { caption: "Uruguay", code: "uy" },
  { caption: "Uzbekistan", code: "uz" },
  { caption: "Vanuatu", code: "vu" },
  { caption: "Venezuela", code: "ve" },
  { caption: "Vietnam", code: "vietnamese" },
  { caption: "Wallis and Futuna", code: "wf" },
  { caption: "Yemen", code: "ye" },
  { caption: "Zambia", code: "zm" },
  { caption: "Zimbabwe", code: "zw" },
]