import React from "react";
import data from "../data/trustUsSection.json";

interface TrustUsData {
  title: string;
  imageLeft: string;
  number: string;
  imageRight: string;
}

const TrustUs: React.FC = () => {
  const trustUsData: TrustUsData = data;

  return (
    <section className="trustus">
      <div className="header-trustus">
        <h2>{trustUsData.title}</h2>
      </div>

      <div className="container-trustus">
        <div className="content-trustus">
          <img src={data.imageLeft} alt="Mockup" width={320} height={400} />
          <h3>{trustUsData.number}</h3>
          <img src={data.imageRight} alt="Mockup" width={320} height={400} />
        </div>
      </div>
    </section>
  );
};

export default TrustUs;
