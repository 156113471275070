import React from "react";

export interface CardServiceProps {
  id: number;
  icon: string;
  title: string;
  description: string;
}

const CardService: React.FC<CardServiceProps> = ({ id, icon, title, description }) => {
  return (
    <div key={id.toString()} className="card" id={id.toString()}>
      <img src={icon} alt="Service-icon" width={200} height={200} />
      <div className="info">
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default CardService;