import axios from 'axios';

export async function getEmailAddresses() {
  try {
    // Send request
    const emails = await axios.get(`${process.env.REACT_APP_SERVER_URL}/getEmailAddresses`, { withCredentials: true });
    return {
      isOk: true,
      data: emails.data.emailAddresses,
    };
  } catch {
    return {
      isOk: false,
      message: 'Failed to fetch emails',
    };
  }
}

export async function sendEmail(emailInfo) {
  const { recipients, subject, body, sendAsGroup } = emailInfo;
  try {
    // Send request
    const emails = await axios.post(`${process.env.REACT_APP_SERVER_URL}/sendEmail`, {
      recipients,
      subject,
      body,
      sendAsGroup,
    }, { withCredentials: true });
    return {
      isOk: true,
      data: emails.data.message,
    };
  } catch(error: any) {
    console.log(error);
    return {
      isOk: false,
      message: error.response.data.message,
    };
  }
}

export async function addEmailAddress(emailInfo) {
  const { username, emailAddress, tags } = emailInfo;
  try {
    // Send request
    const emails = await axios.post(`${process.env.REACT_APP_SERVER_URL}/emailAddress`, {
      username,
      emailAddress,
      tags,
    }, { withCredentials: true });
    return {
      isOk: true,
      data: emails.data.emailWithTags,
    };
  } catch {
    return {
      isOk: false,
      message: 'Failed to add email',
    };
  }
}

export async function updateEmailAddress(emailInfo) {
  try {
    const response = await axios.put(`${process.env.REACT_APP_SERVER_URL}/emailAddress`, {
      emailInfo
    }, { withCredentials: true});
    return {
      isOk: true,
      data: response.data.email,
    }
  } catch (error) {
    return {
      isOk: false,
      message: 'Failed to update email',
    };
  }
}