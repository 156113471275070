import { useCallback, useState } from "react";
import "./calendar.css";
import { Google_Calender_Country_Mapping } from "../../shared/constants";
import { getPublicEventsByCalendarId } from "../../api/calendar";
import { toast } from "react-toastify";
import { GoogleEventCard } from "../../components";
import { EventNewForm } from "../../components";
import { Button, Popup, SelectBox } from "devextreme-react";
import { getEventsByCountry } from "../../api/event";
import { EmailAddresses } from "../email-addresses/email-addresses";
import { calendarColors } from "../../shared/constants";
import {
  Card,
  CardHeader,
  Paper,
  Typography,
} from "@mui/material";

export const EventCalendar = () => {
  const languageCode = "en";
  const [showCalendar, setShowCalendar] = useState(false);
  const [events, setEvents] = useState<any>([]);
  const [countrySelected, setCountrySelected] = useState();
  const [popupVisible, setPopupVisible] = useState(false);
  const [showEmailSelector, setShowEmailSelector] = useState(false);
  const [currentEvent, setCurrentEvent] = useState<any>(null);

  const changePopupVisibility = useCallback(() => {
    setPopupVisible(!popupVisible);
  }, [popupVisible]);

  const sortByDate = (event1, event2) => {
    if (new Date(event1.from) > new Date(event2.from)) return 1;
    else return -1;
  };

  const filterEvents = (events) => {
    return events.data.filter((event) => {
      return (
        event.visibility === "public" &&
        event.status === "confirmed" &&
        (new Date(event.start.date).getFullYear() ===
          new Date().getFullYear() ||
          new Date(event.end.date).getFullYear() ===
            new Date().getFullYear()) &&
        new Date(event.end.date) >= new Date()
      );
    });
  };

  const onCountrySelected = async (e) => {
    setShowCalendar(false);
    setCountrySelected(e.value);
    if (e.value === null) {
      return;
    }
    const calendarId = `${languageCode}.${e.value}`;
    const events = await getPublicEventsByCalendarId(calendarId);
    if (events.isOk) {
      let publicEvents = filterEvents(events);
      publicEvents = publicEvents.map((event) => {
        return {
          id: event.id,
          title: event.summary,
          from: event.start.date,
          to: event.end.date,
          country: e.value,
        };
      });
      const customEvents = await getEventsByCountry(e.value);
      if (customEvents.isOk)
        publicEvents = [...publicEvents, ...customEvents.data];
      else
        toast.warning("Unable to retrieve custom events", {
          position: "top-center",
        });
      publicEvents.sort(sortByDate);
      setEvents(publicEvents);
      setShowCalendar(true);
    } else {
      toast.warning("Unable to fetch calendar", {
        position: "top-center",
      });
    }
  };

  const onSendEmailEvent = async (eventId) => {
    const event: any = events.find((event: any) => event.id === eventId);
    setCurrentEvent(event);
    setShowEmailSelector(true);
  };

  const addEvent = (newEvent) => {
    setEvents([...events, newEvent]);
  };

  const legend = () => {
    const legend = [
      {
        color: calendarColors.past,
        text: "Past events",
      },
      {
        color: calendarColors.current,
        text: "Current events",
      },
      {
        color: calendarColors.nearfuture,
        text: "Events in near future",
      },
      {
        color: calendarColors.future,
        text: "Events in future",
      },
    ];
    return (
      <Paper
        elevation={0}
        style={{ padding: "5px"}}
      >
        <div
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", }}
        >
          {legend.map((item) => {
            return (
              <Card
                sx={{ marginInline: "5px", background: item.color, height: 27 }}
                key={item.text}
              >
                <CardHeader
                  sx={{ padding: "5px" }}
                  title={<Typography variant="caption">{item.text}</Typography>}
                />
              </Card>
            );
          })}
        </div>
      </Paper>
    );
  };
  return (
    <div className="calendar">
      <Paper elevation={1} style={{ padding: "10px" }}>
        <div className="mx-5 d-flex flex-row flex-wrap justify-content-between align-items-center">
          <div>
            <SelectBox
              label="Country/Religion"
              labelMode="floating"
              onValueChanged={onCountrySelected}
              value={countrySelected}
              items={Google_Calender_Country_Mapping}
              displayExpr="caption"
              valueExpr="code"
              searchEnabled
              showClearButton
            />
          </div>
          {showCalendar && legend()}
          {showCalendar && (
            <Button
              text="Add Event"
              icon="fa-solid fa-calendar-plus"
              onClick={(e) => changePopupVisibility()}
              style={{ backgroundColor: "#038bc9", color: "white"}}
              className="mx-5 justify-content-end"
            />
          )}
        </div>
      </Paper>
      {showCalendar && (
        <div className="mt-3 d-flex flex-row justify-content-around flex-wrap">
          {events.map((event: any) => (
            <div className="my-3" key={event.id}>
              <GoogleEventCard
                eventData={event}
                onSendEmailEvent={onSendEmailEvent}
              />
            </div>
          ))}
        </div>
      )}
      <EventNewForm
        visible={popupVisible}
        changeVisibility={changePopupVisibility}
        addEvent={addEvent}
        country={countrySelected}
      />
      <Popup
        visible={showEmailSelector}
        onHiding={() => setShowEmailSelector(false)}
        dragEnabled={false}
        hideOnOutsideClick={true}
        showCloseButton={false}
      >
        <EmailAddresses
          forEmailSendingOnly
          title={"Select Email Addresses"}
          publicEvent={currentEvent}
        />
      </Popup>
    </div>
  );
};
