import axios from 'axios';

export async function syncWithGmail() {
  try {
    // Send request
    const emails = await axios.get(`${process.env.REACT_APP_SERVER_URL}/syncWithGmail`, { withCredentials: true });
    return {
      isOk: true,
    };
  } catch {
    return {
      isOk: false,
      message: 'Failed to sync emails',
    };
  }
}
