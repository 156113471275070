import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface TagState {
  id: string,
  name: string,
  createdAt: string,
  updatedAt: string,
  userId: string,
}

const initialState: TagState[] = [];

export const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    setTags: (state, action) => {
      return action.payload.tags
    },
    addTag: (state, action) => {
      state.push(action.payload.tag)
    },
  },
})

// Action creators are generated for each case reducer function
export const { setTags, addTag } = tagsSlice.actions

export default tagsSlice.reducer