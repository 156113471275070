import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { appInfo } from './app-info';
import { appRoutes } from './app-routes';
import { SideNavOuterToolbar as SideNavBarLayout } from './layouts';

import { setEmailAddresses } from './redux/reducer/emailAddressesSlice';
import { setTags } from './redux/reducer/tagsSlice';
import { getTags } from "./api/tag";
import { getTrustedEmailAddresses } from "./api/trustedEmail"
import { useDispatch } from 'react-redux';
import { getEmailAddresses } from './api/email';
import { setTrustedEmailAddresses } from './redux/reducer/trustedEmailAddressesSlice';
import { getSettings } from './api/settings';
import { setSettings } from './redux/reducer/settingsSlice';

export const Content = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    getEmailAddresses().then((emails: any) => {
      dispatch(setEmailAddresses({ emailAddresses: emails.data }));
      getTags().then((tags: any) => {
        dispatch(setTags({ tags: tags.data }));
      });
      getTrustedEmailAddresses().then((trustedEmails: any) => {
        dispatch(setTrustedEmailAddresses({ trustedEmailAddresses: trustedEmails.data }))
      })
      getSettings().then((settings: any) => {
        dispatch(setSettings({ settings: settings.data }))
      })
    }); 
  }, [])

  return (
    <>
      <SideNavBarLayout title={appInfo.title}>
        <Routes>
          {appRoutes.map(({ path, element }) => (
            <Route key={path} path={path} element={element} />
          ))}
          <Route path='*' element={<Navigate to='email-addresses' replace={true}/>} />
        </Routes>
      </SideNavBarLayout>
    </>
  );
};
