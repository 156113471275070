import axios from 'axios';

export async function getSettings() {
  try {
    // Send request
    const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/settings`, { withCredentials: true });
    return {
      isOk: true,
      data: response.data.settings,
    };
  } catch {
    return {
      isOk: false,
      message: 'Failed to fetch Credit Info',
    };
  }
}

export async function updateSettings(settingsData) {
  try {
    const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/settings`, {
      updatedSettings: settingsData
    }, {withCredentials: true});
    return {
      isOk: true,
      data: response.data.settings
    }
  } catch (error) {
    
  }
}