
import React, { useState, useRef } from 'react';
import TagBox from "devextreme-react/tag-box";
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { Button } from 'devextreme-react';
import ValidationGroup from 'devextreme-react/validation-group';
import Form, { Item as FormItem, GroupItem, ColCountByScreen } from 'devextreme-react/form';
import { FormTextbox, FormPhotoUploader } from '..';
import { EmailRule } from 'devextreme-react/validator';
import { getSizeQualifier } from '../../utils/media-query';
import { useScreenSize } from './../../utils/media-query';
import { addEmailAddress } from '../../api/email';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

interface Email {
  username: string,
  emailAddress: string,
  tags: any[],
}

const newEmail: Email = {
  username: '',
  emailAddress: '',
  tags: []
}


export const EmailNewForm = ({visible, changeVisibility, addEmail}) => {
  const tags = useSelector((state: any) => state.tags)
  const [newEmailInfo, setNewEmailInfo] = useState<Email>(newEmail);
  const validationGroup = useRef<ValidationGroup>(null);
  const { isXSmall } = useScreenSize();

  const updateField = (field: string) => (value) => {
    setNewEmailInfo((prevState) => ({ ...prevState, ...{ [field]: value } }));
  };

  const onSaveClick = () => {
    if (!validationGroup.current?.instance.validate().isValid) return;
    changeVisibility();
    addEmailAddress(newEmailInfo).then((res) => {
      if (res.isOk) {
        addEmail(res.data)
        toast.success("Email Address Added Successfully", {
          position: 'top-center',
        })
      } else {
        toast.error("Failed to Add Email Address", {
          position: 'top-center',
        })
      }
    });
    setNewEmailInfo(newEmail);
  }

  const onCancelClick = () => {
    changeVisibility();
    setNewEmailInfo(newEmail);
  }

  
  return (
    <Popup
      title={"New Email"}
      visible={visible}
      fullScreen={isXSmall}
      width='480px'
      height='auto'
    >
      <ToolbarItem
        widget='dxButton'
        toolbar='bottom'
        location='after'
      >
        <Button
          text= 'Save'
          stylingMode= 'outlined'
          type= 'default'
          onClick={onSaveClick}
        />
      </ToolbarItem>
      <ToolbarItem
        widget='dxButton'
        toolbar='bottom'
        location='after'
      >
        <Button
          text='Cancel'
          stylingMode='text'
          type='default'
          onClick={onCancelClick}
        />
      </ToolbarItem>
    <ValidationGroup ref={validationGroup}>
      <Form
        className='plain-styled-form'
        screenByWidth={getSizeQualifier}
      >

        <GroupItem>
          <ColCountByScreen xs={1} sm={2} md={2} lg={2} />
          <FormItem>
            <FormTextbox
              label='Username'
              value={newEmailInfo.username}
              isEditing={false}
              icon='user'
              onValueChange={updateField('username')}
            />
          </FormItem>
          <FormItem>
            <FormTextbox
              value={newEmailInfo.emailAddress}
              onValueChange={updateField('emailAddress')}
              isEditing={false}
              icon='email'
              label='Email Address'
            >
              <EmailRule />
            </FormTextbox>
          </FormItem>
        </GroupItem>

        <GroupItem cssClass='contact-fields-group'>
          <ColCountByScreen xs={1} sm={2} md={2} lg={2} />
          <TagBox
            value={newEmailInfo.tags}
            dataSource={tags}
            displayExpr="name"
            multiline={false}
            searchEnabled
            placeholder='Add Tags'
            hideSelectedItems 
            onValueChanged={(e) => { 
              if (e.event) updateField('tags')(e.value)
            }}
          />
        </GroupItem>
      </Form>
    </ValidationGroup>
    </Popup>
  );
};
