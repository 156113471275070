import React, { useState } from "react";

import { HashRouter as Router, useLocation } from "react-router-dom";

import LoadPanel from "devextreme-react/load-panel";

import { NavigationProvider } from "./contexts/navigation";
import { AuthProvider, useAuth } from "./contexts/auth";
import { useScreenSizeClass } from "./utils/media-query";
import { Content } from "./Content";
// import { UnauthenticatedContent } from './UnauthenticatedContent';

import { ToastContainer } from "react-toastify";

import "devextreme/scss/bundles/dx.material.blue.light.compact.scss";
import "devexpress-gantt/dist/dx-gantt.css";
import "./styles.scss";
import "react-toastify/dist/ReactToastify.css";
import { AuthCallback, GoogleAuth, LandingPage } from "./pages";
import { isAuthorized } from "../src/api/auth";

function RootApp() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const currPath = window.location.pathname;
  if (currPath === "/" ) {
    return <LandingPage />;
  } else if ((currPath === "/sign-in" || currPath === "/sign-up")) {
    isAuthorized().then((res) => {
      setIsAuthenticated(res.isAuthorized);
    });
    return isAuthenticated? <Content/> : <GoogleAuth />;
  } else if (currPath === "/auth-callback") {
    return <AuthCallback />;
  } else {
    isAuthorized().then((res) => {
      setIsAuthenticated(res.isAuthorized);
    });
    return isAuthenticated ? <Content /> : <LandingPage />;
  }
}

export const App = () => {
  const screenSizeClass = useScreenSizeClass();

  return (
    <Router>
      <AuthProvider>
        <NavigationProvider>
          <div className={`app ${screenSizeClass}`}>
            <RootApp />
            <ToastContainer />
          </div>
        </NavigationProvider>
      </AuthProvider>
    </Router>
  );
};
