import axios from 'axios';

export async function getProfile() {
  try {
    // Send request
    const profile = await axios.get(`${process.env.REACT_APP_SERVER_URL}/getProfile`, { withCredentials: true });
    return {
      isOk: true,
      data: profile
    };
  } catch {
    return {
      isOk: false,
      message: 'Failed to fetch profile',
    };
  }
}