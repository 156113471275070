import { useMemo, useState, useEffect, useRef } from "react";
import { Button, TextArea, LoadIndicator } from "devextreme-react";
import { Allotment } from "allotment";
import "./email-generator-popup-v2.css"; // Import the CSS file
import "allotment/dist/style.css";
import { chatWithAI } from "../../api/openai";
import { toast } from "react-toastify";
import JoditEditor from "jodit-react";
import { Badge } from "@mui/material";
export interface messageProps {
  role: "system" | "user" | "assistant";
  content: string;
}

const defaultPrompt = (
  emailAddressList: any,
  subject: string,
  publicEvent: any
) => {
  let prompt = "";
  if (emailAddressList) {
    if (emailAddressList.length === 1) {
      prompt = `Individual Email to: ${emailAddressList[0].split("@")[0]}`;
    } else {
      prompt = `Group Email`;
    }
  }
  if (subject) {
    if (prompt !== "") {
      prompt = prompt + "\n";
    }
    prompt = prompt + `Subject: ${subject}`;
  }
  if (publicEvent && publicEvent.hasOwnProperty("from") && publicEvent.hasOwnProperty("to")) {
    if (prompt !== "") {
      prompt = prompt + "\n";
    }
    prompt = prompt + `Form: ${publicEvent.from} To: ${publicEvent.to}`;
  }
  if(prompt !== "") {
    prompt = prompt + "\n";
  }

  return prompt;
};

const EmailGeneratorPopupV2 = ({
  callback,
  emailAddressList,
  subject,
  publicEvent,
}: any) => {

  const promptRef = useRef<TextArea>(null);

  const [inputText, setInputText] = useState(defaultPrompt(emailAddressList, subject, publicEvent));
  const [messages, setMessages] = useState<messageProps[]>([]);
  const [loading, setLoading] = useState(false);
  const [indexOfPromptHighlighted, setIndexOfPromptHighlighted] = useState(0);
  const [indexOfEmailInViewPort, setIndexOfEmailInViewPort] = useState(-1);

  useEffect(() => {
    if (messages.length !== 0) {
      const lastMessage = messages[messages.length - 1];
      if (lastMessage.role === "assistant") {
        setIndexOfPromptHighlighted(messages.length - 2);
        setIndexOfEmailInViewPort(messages.length - 1);
      } else {
        setInputText("");
        setIndexOfPromptHighlighted(messages.length - 1);
        setIndexOfEmailInViewPort(-1);

        handleGenerateEmail();
      }
    }
  }, [messages]);

  useEffect(() => {
    console.log('hi')
    if (promptRef.current) {
      console.log('bye')
      promptRef.current.instance.focus();
    }
  }, []);

  const handleGenerateEmail = () => {
    setLoading(true);
    chatWithAI(messages).then((response) => {
      if (response.isOk) {
        setMessages((prevMessages) => [...prevMessages, response.text]);
      } else {
        toast.error(response.message);
        setInputText(messages[messages.length - 1].content);
        setMessages((prevMessages) =>
          prevMessages.slice(0, prevMessages.length - 1)
        );
      }
      setLoading(false);
    });
  };

  const handleInsertText = () => {
    callback(
      indexOfEmailInViewPort !== -1 && messages.length >= indexOfEmailInViewPort
        ? messages[indexOfEmailInViewPort].content
        : ""
    );
  };

  const handleNewMessage = () => {
    if (!loading && inputText.trim() !== "") {
      const newMessage: messageProps = {
        role: "user",
        content: inputText,
      };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
    }
  };

  const joditConfig = useMemo(() => {
    return {
      iframe: true,
      readonly: true,
      disablePlugins: ["powered-by-jodit"],
      toolbar: false,
      allowResizeY: true,
      maxHeight: "600px",
    };
  }, []);

  return (
    <>
      <div id="email-generator" className="email-generator-popup-v2">
        <Allotment
          defaultSizes={[50, 100]}
          vertical={false}
          className="allotment"
        >
          <Allotment.Pane visible minSize={500}>
            <div
              style={{
                display: "flex",
                height: "100%",
                flexDirection: "column",
              }}
            >
              <div className="messages-container">
                {messages.length === 0 && (
                  <span className="history">Prompt History</span>
                )}
                {messages.map((message: any, index: number) => {
                  if (message.role !== "user") return;
                  return (
                    <TextArea
                      key={index}
                      tabIndex={index}
                      label={`Prompt ${index / 2}`}
                      labelMode="hidden"
                      defaultValue={message.content}
                      autoResizeEnabled
                      readOnly
                      className={
                        index === indexOfPromptHighlighted
                          ? "message border border-primary border-3"
                          : "message border border-dark border-2"
                      }
                      onFocusIn={() => {
                        setIndexOfEmailInViewPort(index + 1);
                        setIndexOfPromptHighlighted(index);
                      }}
                      style={{ background: "#fff" }}
                    />
                  );
                })}
              </div>
              <Badge
                badgeContent={
                  messages.length === 0 ? "Write your prompt here" : ""
                }
                color="success"
                overlap="circular"
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                hidden={messages.length !== 0}
              />
              <TextArea
                ref={promptRef}
                stylingMode="outlined"
                valueChangeEvent="keyup"
                onValueChanged={(e: any) => setInputText(e.value)}
                value={inputText}
                readOnly={loading}
                placeholder="Prompt here..."
                autoResizeEnabled
                style={{ background: "#fff" }}
              />
              <Button
                text="Generate Email"
                icon="refresh"
                onClick={handleNewMessage}
                disabled={loading}
                type="default"
                style={{ marginTop: "5px" }}
                hint="Generate email from prompt"
              />
            </div>
          </Allotment.Pane>
          <Allotment.Pane visible minSize={500}>
            <div className="email-viewer">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  text="Insert"
                  icon="download"
                  onClick={handleInsertText}
                  disabled={
                    loading ||
                    !(
                      indexOfEmailInViewPort !== -1 &&
                      indexOfEmailInViewPort <= messages.length
                    )
                  }
                  type="default"
                  hint="Insert email into editor"
                />
              </div>
              <div style={{ marginTop: "5px", height: "100%" }}>
                {!loading &&
                  !(
                    indexOfEmailInViewPort !== -1 &&
                    indexOfEmailInViewPort <= messages.length
                  ) && (
                    <div style={{ textAlign: "center", marginTop: "10px" }}>
                      Select a prompt to view email, or generate a new email.
                    </div>
                  )}
                {!loading &&
                  indexOfEmailInViewPort !== -1 &&
                  indexOfEmailInViewPort <= messages.length && (
                    <JoditEditor
                      value={
                        indexOfEmailInViewPort !== -1 &&
                        indexOfEmailInViewPort <= messages.length
                          ? messages[indexOfEmailInViewPort].content
                          : "Select a prompt to view email, or generate a new email."
                      }
                      config={joditConfig}
                    />
                  )}
                {loading && (
                  <div style={{ textAlign: "center", marginTop: "10px" }}>
                    <LoadIndicator />
                  </div>
                )}
              </div>
            </div>
          </Allotment.Pane>
        </Allotment>
      </div>
    </>
  );
};

export default EmailGeneratorPopupV2;
