import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { CardHeader } from "@mui/material";
import { useEffect, useState } from "react";
import { calendarColors } from "../../shared/constants";

export const GoogleEventCard = ({ eventData, onSendEmailEvent }) => {
  const [backgroundColor, setBackgroundColor] = useState(calendarColors.future);
  const currDate = new Date().setHours(0, 0, 0, 0);
  const startDate = new Date(eventData.from).setHours(0, 0, 0, 0);
  const endDate = new Date(eventData.to).setHours(0, 0, 0, 0);

  const getDaysDifference = (date1, date2) => {
    var diffInMilliseconds = Math.abs(date1 - date2);
    return Math.trunc(diffInMilliseconds / (1000 * 60 * 60 * 24));
  };

  useEffect(() => {
    if (endDate < currDate) {
      setBackgroundColor(calendarColors.past);
    } else if (currDate >= startDate && currDate <= endDate) {
      setBackgroundColor(calendarColors.current);
    } else if (getDaysDifference(currDate, startDate) < 30) {
      setBackgroundColor(calendarColors.nearfuture);
    }
  }, []);

  return (
    <div>
      <Card
        sx={{
          width: 300,
          height: 100,
          backgroundColor: backgroundColor,
          opacity: backgroundColor === calendarColors.past ? 0.7 : 1,
          display: "flex",
          flexDirection: "row",
          cursor: "pointer",
        }}
        onClick={(e) => onSendEmailEvent(eventData.id)}
      >
        <CardHeader
          title={eventData.title}
          subheader={
            <Typography
              variant={
                backgroundColor === calendarColors.current
                  ? "h6"
                  : "subtitle2"
              }
            >
              {`${eventData.from} to ${eventData.to}`}
            </Typography>
          }
        />
      </Card>
    </div>
  );
};