import { createSlice } from '@reduxjs/toolkit'

export interface SettingsState {
  id: string,
  hotDuration: number,
  warmDuration: number,
  userId: string,
}

const initialState: SettingsState | null = null;

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSettings: (state, action) => {
      return action.payload.settings;
    },
    updateSettings: (state, action) => {
      return action.payload.settings;
    }
  },
})

export const { setSettings, updateSettings } = settingsSlice.actions

export default settingsSlice.reducer
