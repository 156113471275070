import React from "react";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Services from "./components/Services";
import Management from "./components/Management";
import TrustUs from "./components/TrustUs";

import "./styles/style.css"
import Footer from "./components/Footer";

export const LandingPage: React.FC = () => {
  return (
    <>
      <div>
        <Header />
        <Hero />
        <Services />
        <Management />
        <TrustUs />
        <Footer />
      </div>
    </>
  );
};