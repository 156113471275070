import { Button } from "devextreme-react";
import DataGrid, { Column, Item, Scrolling, Toolbar } from "devextreme-react/data-grid";
import React, { useCallback } from "react";

export const QuickTagger = ({emailAddresses, labelChooserRender}) => {
  let filteredEmailAddresses = [];

  const filterEmailAddresses = useCallback(() => {
    filteredEmailAddresses = emailAddresses.filter((emailAddress: any) => emailAddress.tags.length === 0);
    if (filteredEmailAddresses.length === 0) {
      filteredEmailAddresses = emailAddresses.slice(0, 3);
    }
    if (filteredEmailAddresses.length > 3) {
      filteredEmailAddresses = filteredEmailAddresses.slice(0, 3);
    }
  }, [emailAddresses]);


  const refresh = () => {
    filterEmailAddresses();
  };

  filterEmailAddresses();

  return (
    <DataGrid
    className="grid"
    noDataText="Good job! You've tagged all the email addresses!"
    height="100%"
    dataSource={filteredEmailAddresses}
    keyExpr="id"
    allowColumnResizing
  >
    <Scrolling mode="virtual" />
    {/* <Toolbar>
      <Item location="before">
        <div className="grid-header">{"Quick Tagger"}</div>
      </Item>
      <Item
        location="after"
        locateInMenu="auto"
        showText="inMenu"
        widget="dxButton"
      >
        <Button
          icon="refresh"
          text="Refresh"
          stylingMode="text"
          onClick={refresh}
        />
      </Item>
    </Toolbar> */}
    <Column dataField="id" visible={false} showInColumnChooser={false} />
    <Column
      dataField="username"
      caption="Sender"
      sortOrder="asc"
    />
    <Column
      dataField="emailAddress"
      caption="Sender Email"
    />
    <Column
      dataField="isImportant"
      caption="Is Important"
      dataType="boolean"
    />
    <Column
      dataField="lastContacted"
      caption="Last Contacted"
      dataType="datetime"
      format="dd/MM/yyyy HH:mm"
      allowEditing={false}
    />
    <Column
      caption="Tags"
      dataType="string"
      cellRender={labelChooserRender}
      minWidth={200}
    />
  </DataGrid>
  );
}