import { createSlice } from '@reduxjs/toolkit'

export interface EmailAddressState {
  id: string,
  username: string,
  emailAddress: string,
  lastConnected: string,
  createdAt: string,
  updatedAt: string,
  userId: string,
  tags: Array<any>,
}

const initialState: EmailAddressState[] = [];

export const emailAddressesSlice = createSlice({
  name: 'emailAddresses',
  initialState,
  reducers: {
    setEmailAddresses: (state, action) => {
      return action.payload.emailAddresses;
    },
    addEmailAddress: (state, action) => {
      state.push(action.payload.emailAddress)
    },
    updateEmailAddressTags: (state, action) => {
      state[action.payload.index].tags = action.payload.tags
    },
    updateEmailAddress: (state, action) => {
      state[action.payload.index] = action.payload.emailAddress
    }
  },
})

// Action creators are generated for each case reducer function
export const { setEmailAddresses, addEmailAddress, updateEmailAddressTags, updateEmailAddress } = emailAddressesSlice.actions

export default emailAddressesSlice.reducer