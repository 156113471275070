import axios from "axios";

export async function verifyCaptcha(token) {
  try {
    // Send request
    const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/reCaptcha`, {
      token
    }, { withCredentials: true });
    return {
      isOk: true,
      data: response.data.success,
    };
  } catch {
    return {
      isOk: false,
      message: 'Failed to verify captcha, try again later',
    };
  }
}
