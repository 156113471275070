import { GoogleLogin } from "@react-oauth/google";
import React from "react";
import { login } from "../../../api/auth";
import { toast } from "react-toastify";
import { Button } from "devextreme-react";

const Header: React.FC = () => {
  return (
    <header className="landing-page-header">
      <div className="logo">
        <a style={{ display: "flex" }} href="/">
          <img
            src={"../For Web/svg/Color logo - no background.svg"}
            alt="Logo"
            height={50}
          />
        </a>
      </div>
      <div>
        <Button
          style={{ width: "150px", height: 45 }}
          text="Sign In"
          type="default"
          onClick={() => {
            window.location.href = "/sign-in";
          }}
        />
      </div>
    </header>
  );
};

export default Header;