import React, { useEffect, useState } from "react";
import DataGrid, {
  ColumnChooser,
  Editing,
  FilterRow,
  Item,
  Scrolling,
  SearchPanel,
  Sorting,
  LoadPanel,
  Toolbar,
  Column,
  Button as GridButton,
} from "devextreme-react/data-grid";
import "../email-addresses/email-addresses.scss";
import Button from "devextreme-react/button";
import { Popup } from "devextreme-react";
import { confirm } from "devextreme/ui/dialog";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { EmailAddresses } from "../email-addresses/email-addresses";
import {
  deleteTemplate,
  getTemplates,
} from "../../api/template";
export interface Template {
  id: string;
  name: string;
  description: string;
  emailSubject: string;
  emailBody: string;
  isPublic: boolean;
  createdAt?: Date;
  updatedAt?: Date;
}

interface TemplateDashboardProps {
  selectOnly?: boolean;
  callbackOnSelection?: (template: Template) => void;
}

export const TemplateDashboard: React.FC<TemplateDashboardProps> = ({
  selectOnly = false,
  callbackOnSelection = undefined,
}) => {
  const navigate = useNavigate();
  const [templates, setTemplates] = useState<Template[]>([]);
  const [loading, setLoading] = useState(false);
  const [showEmailSelector, setShowEmailSelector] = useState(false);
  const [emailTemplateToSendEmail, setEmailTemplateToSendEmail] =
    useState<Template | null>(null);

  const navigateToTemplateEditor = (
    mode: "add" | "edit",
    template: Template | null = null
  ) => {
    navigate("/template-editor", {
      state: {
        mode,
        template,
      },
      replace: false,
    });
  };

  useEffect(() => {
    setLoading(true);
    getTemplates()
      .then((res) => {
        if (res.isOk) {
          setTemplates(res.data);
        } else {
          toast.error(res.message);
        }
        console.log("got templates")
      })
      .catch((err) => {
        toast.error(err.message);
      });
      setLoading(false);
  }, []);

  const onDeleteTemplate = (template: Template) => {
    setLoading(true);
    deleteTemplate(template.id)
      .then((res) => {
        if (res.isOk) {
          setTemplates(templates.filter((t) => t.id !== template.id));
          toast.success(`Template '${template.name}' deleted successfully.`);
        } else {
          toast.error(res.message);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
    setLoading(false);
  };

  return (
    <div className="view crm-contact-list">
      <div className="view-wrapper view-wrapper-contact-list">
        <DataGrid
          className="grid"
          noDataText="You have not created any templates yet. Click the 'Create Template' button to get started."
          height="100%"
          dataSource={templates}
          keyExpr="id"
          columnAutoWidth
          onRowClick={
            selectOnly
              ? (e) => {
                  if (!loading && callbackOnSelection) {
                    callbackOnSelection(e.data);
                  }
                }
              : undefined
          }
        >
          <LoadPanel enabled={loading} showIndicator={loading} />
          <SearchPanel visible placeholder="Search Templates" />
          <ColumnChooser enabled />
          <FilterRow visible />
          <Sorting mode="multiple" />
          <Scrolling mode="virtual" />
          <Toolbar>
            <Item location="before">
              <div className="grid-header">{"Templates"}</div>
            </Item>
            <Item location="after" locateInMenu="auto">
              <Button
                icon="plus"
                text="Add Template"
                type="default"
                stylingMode="contained"
                onClick={() => {
                  navigateToTemplateEditor("add");
                }}
                disabled={loading || selectOnly}
              />
            </Item>
            <Item name="columnChooserButton" locateInMenu="auto" />
            <Item name="searchPanel" locateInMenu="auto" visible />
          </Toolbar>
          <Column
            dataField="id"
            caption="ID"
            visible={false}
            showInColumnChooser={false}
          />
          <Column dataField="name" caption="Name" />
          <Column dataField="description" caption="Description" />
          <Column dataField="emailSubject" caption="Email Subject" />
          <Column
            dataField="emailBody"
            caption="Email Body"
            visible={false}
            showInColumnChooser={false}
          />
          <Column dataField="isPublic" caption="Public" />
          <Column
            dataField="createdAt"
            dataType="datetime"
            format="dd/MM/yyyy HH:mm"
            caption="Created At"
            visible={false}
          />
          <Column
            dataField="updatedAt"
            dataType="datetime"
            format="dd/MM/yyyy HH:mm"
            caption="Updated At"
          />
          <Column type="buttons" caption={"Actions"}>
            <GridButton
              disabled={loading || selectOnly}
              hint="Send Email"
              text="Send Email"
              icon={"email"}
              onClick={(e: any) => {
                setShowEmailSelector(true);
                setEmailTemplateToSendEmail(e.row.data);
              }}
            />
            <GridButton
              disabled={loading || selectOnly}
              hint="Delete Template"
              text="Delete"
              icon={"trash"}
              onClick={(e: any) => {
                const res = confirm(
                  `Are you sure you want to delete <b>${e.row.data.name}</b> template?`,
                  "Confirm Delete"
                );
                res.then((dialogResult: any) => {
                  if (dialogResult) {
                    onDeleteTemplate(e.row.data);
                  }
                });
              }}
            />
            <GridButton
              disabled={loading || selectOnly}
              hint="Edit Template"
              text="Edit"
              icon={"edit"}
              onClick={(e) => {
                navigateToTemplateEditor("edit", e.row.data);
              }}
            />
          </Column>
        </DataGrid>
        {showEmailSelector && (
          <Popup
            visible={showEmailSelector}
            onHiding={() => setShowEmailSelector(false)}
            dragEnabled={false}
            hideOnOutsideClick={true}
            showCloseButton={false}
          >
            <EmailAddresses
              forEmailSendingOnly
              title={"Select Email Addresses"}
              emailTemplate={emailTemplateToSendEmail}
            />
          </Popup>
        )}
      </div>
    </div>
  );
};
