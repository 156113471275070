import axios from 'axios';

export async function getOpenAiApiKey() {
  try {
    // Send request
    const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/openAiApiKey`, { withCredentials: true });
    return {
      isOk: true,
      data: response.data.openAiApiKey,
    };
  } catch {
    return {
      isOk: false,
      message: 'Failed to fetch OpenAI API Key',
    };
  }
}

export async function addOpenAiApiKey(key: string) {
  try {
    // Send request
    const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/openAiApiKey`, {
      key
    }, { withCredentials: true });
    return {
      isOk: true,
      data: response.data.openAiApiKey,
    };
  } catch {
    return {
      isOk: false,
      message: 'Failed to store OpenAI API Key',
    };
  }
}

export async function updateOpenAiApiKey(apiId: string, apiKey: string) {
  try {
    // Send request
    const response = await axios.put(`${process.env.REACT_APP_SERVER_URL}/openAiApiKey/${apiId}`, {
      key: apiKey,
    }, { withCredentials: true });
    return {
      isOk: true,
      data: response.data.openAiApiKey,
    };
  } catch {
    return {
      isOk: false,
      message: 'Failed to update OpenAI API Key',
    };
  }
}