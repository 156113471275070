import { defaultUser } from '../utils/default-user';
import axios from 'axios';

export async function authorize() {
  try {
    // Send request
    const response:any = await axios.get(`${process.env.REACT_APP_SERVER_URL}`, { withCredentials: true });
    return {
      isOk: false,
      url: response.data.url,
    };
  } catch {
    return {
      isOk: false,
      url: null,
    };
  }
}

export async function isAuthorized() {
  try {
    // Send request
    const response:any = await axios.get(`${process.env.REACT_APP_SERVER_URL}/isAuthorized`, { withCredentials: true });
    return {
      isOk: false,
      isAuthorized: response.data.authorized,
    };
  } catch {
    return {
      isOk: false,
      isAuthorized: false,
    };
  }
}

/* eslint-disable @typescript-eslint/no-unused-vars */
export async function signIn(email: string, password: string) {
  try {
    // Send request
    return {
      isOk: true,
      data: { ...defaultUser, email },
    };
  } catch {
    return {
      isOk: false,
      message: 'Authentication failed',
    };
  }
}

export async function getUser() {
  try {
    // Send request

    return {
      isOk: true,
      data: defaultUser,
    };
  } catch {
    return {
      isOk: false,
    };
  }
}

export async function createAccount(email: string, password: string) {
  try {
    // Send request
    return {
      isOk: true,
    };
  } catch {
    return {
      isOk: false,
      message: 'Failed to create account',
    };
  }
}

export async function changePassword(email: string, recoveryCode?: string) {
  try {
    // Send request
    return {
      isOk: true,
      data: { email },
    };
  } catch {
    return {
      isOk: false,
      message: 'Failed to change password',
    };
  }
}

export async function resetPassword(email: string) {
  try {
    // Send request
    return {
      isOk: true,
    };
  } catch {
    return {
      isOk: false,
      message: 'Failed to reset password',
    };
  }
}

export async function login(credentialResponse) {
  try {
    // Send request
    const response:any = await axios.post(`${process.env.REACT_APP_SERVER_URL}/login`, {
      credentialResponse
    }, { withCredentials: true });
    return {
      isOk: true,
      url: response.data.url,
    };
  } catch {
    return {
      isOk: false,
      url: null,
    };
  }
}

export async function logout() {
  try {
    // Send request
    const response:any = await axios.get(`${process.env.REACT_APP_SERVER_URL}/logout`, { withCredentials: true });
    return {
      isOk: true,
      url: response.data.url,
    };
  } catch {
    return {
      isOk: false,
      url: null,
    };
  }
}