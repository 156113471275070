import React, { useEffect, useState } from "react";
import "./admin.css";
import {
  Button,
  ColorBox,
  DataGrid,
  LoadIndicator,
  LoadPanel,
  NumberBox,
  TextBox,
} from "devextreme-react";
import TabPanel, { Item } from "devextreme-react/tab-panel";
import { getTags, addTag } from "../../api/tag";
import { syncWithGmail } from "../../api/syncGmail";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import {
  addTag as addTagInRedux,
  setTags as setTagsInRedux,
} from "../../redux/reducer/tagsSlice";
import { updateGmailSync } from "../../redux/reducer/gmailSlice";
import {
  addTrustedEmailAddress as addTrustedEmailAddressInRedux,
  deleteTrustedEmailAddress as deleteTrustedEmailAddressInRedux,
} from "../../redux/reducer/trustedEmailAddressesSlice";
import {
  addTrustedEmailAddress,
  deleteTrustedEmailAddress,
} from "../../api/trustedEmail";
import { getEmailAddresses } from "../../api/email";
import { setEmailAddresses } from "../../redux/reducer/emailAddressesSlice";
import { RuleManager } from "../../components/rule-manager/RuleManager";
import {
  addOpenAiApiKey,
  updateOpenAiApiKey,
} from "../../api/openAiApiKeyManagement";
import { getOpenAiApiKey } from "../../api/openAiApiKeyManagement";
import {
  Column,
  Editing,
  HeaderFilter,
  Pager,
  Paging,
  Scrolling,
  Toolbar,
  Item as ToolbarItem,
} from "devextreme-react/data-grid";
import { applyRules } from "../../api/ruleEngine";
import { getCreditInfo } from "../../api/credit";
import {
  raabtaScoreColors,
  specialCharacterFormat,
} from "../../shared/constants";
import { updateSettings } from "../../api/settings";
import { updateSettings as updateSettingsInRedux } from "../../redux/reducer/settingsSlice";
import { ChipsBoard } from "../../components/chips-board/ChipsBoard";

export const Admin = () => {
  const emailAddresses = useSelector((state: any) => state.emailAddresses);
  const trustedEmailAddresses = useSelector((state: any) =>
    state.trustedEmailAddresses.map((trustedEmail) => {
      return { id: trustedEmail.id, emailAddress: trustedEmail.emailAddress };
    })
  );
  const tags = useSelector((state: any) =>
    state.tags.map((tag) => {
      return { id: tag.id, tag: tag.name };
    })
  );
  const gmail = useSelector((state: any) => state.gmail);
  const settings = useSelector((state: any) => state.settings);
  const [openAiKey, setOpenAiKey] = useState<any>(null);
  const [showApiKey, setShowApiKey] = useState(false);
  const [syncingApiKey, setSyncingApiKey] = useState(false);
  const [applyingRules, setApplyingRules] = useState(false);
  const [credits, setCredits] = useState<number>(0);
  const [syncingCredits, setSyncingCredits] = useState<boolean>(false);

  const fetchOpenAiApiKey = async () => {
    setSyncingApiKey(true);
    try {
      const apiKey = await getOpenAiApiKey();
      if (apiKey.data && apiKey.data.id) {
        setOpenAiKey({ id: apiKey.data.id, key: apiKey.data.key });
      } else {
        setOpenAiKey({ id: "", key: "" });
      }
    } catch (error) {
      toast.error("Error fetching OpenAI API Key");
    }
    setSyncingApiKey(false);
  };

  const fetchCredits = async () => {
    setSyncingCredits(true);
    try {
      const res = await getCreditInfo();
      if (res.isOk) {
        setCredits(res.data);
      }
    } catch (error) {
      toast.error("Error fetching credit information");
    }
    setSyncingCredits(false);
  };

  useEffect(() => {
    fetchOpenAiApiKey();
    fetchCredits();
  }, []);

  const dispatch = useDispatch();

  const onSyncGmailClick = async () => {
    dispatch(updateGmailSync({ syncing: true }));
    await syncWithGmail().then((res) => {
      if (res.isOk) {
        getEmailAddresses().then((emails: any) => {
          dispatch(setEmailAddresses({ emailAddresses: emails.data }));
          getTags().then((tags: any) => {
            dispatch(setTagsInRedux({ tags: tags.data }));
          });
        });
        toast.success("Synced with Gmail");
      } else {
        toast.error("Error syncing with Gmail");
      }
    });
    dispatch(updateGmailSync({ syncing: false }));
  };

  const onTagAdded = (newTag: string) => {
    let isDuplicate = false;
    newTag = newTag.toLowerCase();
    newTag = newTag.trim();
    if (specialCharacterFormat.test(newTag)) {
      toast.warning(
        "Special characters are not allowed; please use alphanumeric characters and hyphen only"
      );
      return;
    }
    tags.forEach((tag) => {
      if (tag.tag === newTag) isDuplicate = true;
    });
    if (isDuplicate) {
      toast.warning("Tag already added");
      return;
    }
    addTag(newTag).then((res) => {
      if (res.isOk) {
        toast.success("Tag Added");
        const newTag = res.data;
        dispatch(addTagInRedux({ tag: newTag }));
      } else {
        toast.error("Error while adding tag");
      }
    });
  };

  const onTrustedEmailAdded = (emailAddress: any) => {
    //validate if the email address is valid using regex
    emailAddress = emailAddress.toLowerCase();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(emailAddress)) {
      toast.warning("Invalid Email Address", {
        position: "top-center",
      });
      return;
    }

    let isDuplicate = false;
    trustedEmailAddresses.forEach((trustedEmail) => {
      if (trustedEmail.emailAddress === emailAddress) isDuplicate = true;
    });
    if (isDuplicate) {
      toast.warning("Email already added", {
        position: "top-center",
      });
      return;
    }
    addTrustedEmailAddress(emailAddress).then((res) => {
      if (res.isOk) {
        toast.success("Trusted Email Deleted", { position: "top-center" });
        const trustedEmailAddress = res.data;
        dispatch(addTrustedEmailAddressInRedux({ trustedEmailAddress }));
      } else {
        toast.warning("Invalid Email Address", {
          position: "top-center",
        });
      }
    });
  };

  const onTagDeleted = (tag: any) => {
    toast.info("Tags cannot be removed", {
      position: "top-center",
    });
  };

  const onTrustedEmailDeleted = (email: any) => {
    deleteTrustedEmailAddress(email.id).then((res) => {
      if (res.isOk) {
        toast.success("Trusted Email Deleted", { position: "top-center" });
        toast.success("Trusted Email Deleted", { position: "top-center" });
        dispatch(deleteTrustedEmailAddressInRedux({ id: email.id }));
      }
    });
  };

  const handleApiKeyChange = async (e: any) => {
    if (syncingApiKey) return;
    setSyncingApiKey(true);
    if (e.value && e.value !== "") {
      if (openAiKey === null) return; //first time
      if (e.value === openAiKey.key) return; // no change
      if (openAiKey && openAiKey.id && openAiKey.id !== "") {
        const res = await updateOpenAiApiKey(openAiKey.id, e.value);
        if (res.isOk) {
          setOpenAiKey({ id: res.data.id, key: res.data.key });
        }
      } else {
        const res = await addOpenAiApiKey(e.value);
        if (res.isOk) {
          setOpenAiKey({ id: res.data.id, key: res.data.key });
        }
      }
    }
    setSyncingApiKey(false);
  };
  
  const handleToggleApiKey = () => {
    setShowApiKey(!showApiKey);
  };

  const onTrustedEmailEditorPreparing = (e: any) => {
    if (e.dataField === "emailAddress") {
      e.editorName = "dxSelectBox"; // Changes the editor's type
      Object.assign(e.editorOptions, {
        acceptCustomValue: true,

        onCustomItemCreating: (args: any) => {
          onTrustedEmailAdded(args.text);
          //end editing
          e.component.cancelEditData();
        },
      });
    }
  };

  const onApplyRules = () => {
    setApplyingRules(true);
    applyRules()
      .then((res) => {
        if (res.isOk) {
          getEmailAddresses().then((emails: any) => {
            dispatch(setEmailAddresses({ emailAddresses: emails.data }));
          });
          toast.success(res.data);
        } else {
          toast.error(res.message);
        }
        setApplyingRules(false);
      })
      .catch(() => toast.error("Error occured while applying rules"));
  };

  const onUpdateRaabtaScoreDuration = async (
    type: "warm" | "hot",
    value: number
  ) => {
    let updatedSettings = { ...settings };
    if (type === "hot") {
      if (value === updatedSettings.hotDuration) return;
      updatedSettings.hotDuration = value;
    } else {
      if (value === updatedSettings.warmDuration) return;
      updatedSettings.warmDuration = value;
    }
    const res = await updateSettings(updatedSettings);
    if (res?.isOk) {
      dispatch(updateSettingsInRedux({ settings: res.data }));
    }
  };

  const tagItems = tags.map((tag: any) => {
    return {
      id: tag.id,
      label: tag.tag,
      count: emailAddresses.filter((email: any) =>
        email.tags.some((t: any) => t.id === tag.id)
      ).length,
    };
  });

  return (
    <div className="container">
      <div className="title">Admin Panel</div>
      <div>
        <TabPanel animationEnabled loop showNavButtons>
          <Item title="Coins" icon="fa-solid fa-coins">
            <div className="item-container">
              <div className="item-card" style={{ width: 400 }}>
                <div className="item-title">Your Account</div>
                {syncingCredits && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <LoadIndicator />
                  </div>
                )}

                {!syncingCredits && (
                  <TextBox
                    stylingMode="outlined"
                    defaultValue={`Your remaining coins: ${credits}`}
                    readOnly
                    hint="1 coin gives you ability to run one email campaign"
                  />
                )}
                <Button
                  className="mt-3 button"
                  icon="fa-solid fa-cart-shopping"
                  text="Buy More Coins"
                  type="default"
                  stylingMode="contained"
                  onClick={() =>
                    toast.error(
                      "Buy more credit functionality is not implemented yet."
                    )
                  }
                  disabled={syncingCredits}
                />
              </div>
            </div>
          </Item>
          <Item title="Tags" icon="tags">
            <div className="item-container">
              <div className="item-card" style={{ width: 600 }}>
                <div className="item-title dx-icon-tag"> Tags</div>
                <ChipsBoard items={tagItems} callback={onTagAdded} />
              </div>
            </div>
          </Item>
          <Item title="Trusted Emails" icon="email">
            <div className="item-container">
              <div className="item-card" style={{ width: 600 }}>
                <DataGrid
                  className="grid-admin"
                  hint="If an email address is in the TO, CC or BCC field of an email send from a trusted email address, it will be considered important.
              It applies only to the new emails received after the sync."
                  dataSource={trustedEmailAddresses}
                  showBorders
                  onRowInserting={(e) =>
                    onTrustedEmailAdded(e.data.emailAddress)
                  }
                  onRowRemoved={(e) => onTrustedEmailDeleted(e.data)}
                  onEditorPreparing={onTrustedEmailEditorPreparing}
                  showColumnHeaders={false}
                >
                  <Toolbar>
                    <ToolbarItem location="before">
                      <div className="grid-header">{"Trusted Emails"}</div>
                    </ToolbarItem>
                    <ToolbarItem location="after" name="addRowButton" />
                  </Toolbar>
                  <Editing allowAdding allowDeleting />
                  <Paging enabled pageSize={5} />
                  <Pager visible showInfo showNavigationButtons />
                  <Column
                    caption="Email Address"
                    dataField="emailAddress"
                    dataType="string"
                    lookup={{
                      dataSource: emailAddresses.map(
                        (email: any) => email.emailAddress
                      ),
                    }}
                  />
                </DataGrid>
              </div>
            </div>
          </Item>
          <Item title="Rules" icon="filter">
            <div className="item-container">
              <div className="item-card" style={{ width: 800 }}>
                <RuleManager />
              </div>
            </div>
          </Item>
          <Item
            title="Raabta Score configration"
            icon="fa-solid fa-screwdriver-wrench"
          >
            <div className="item-container">
              <div className="item-card" style={{ width: 400 }}>
                <div className="item-title">Raabta Score configration</div>
                <div className="field-row">
                  <div
                    className="color-box fa-solid fa-fire"
                    style={{ color: raabtaScoreColors.hot }}
                  ></div>
                  <label title="A contact is considered hot if last contacted date is within the Hot duration">
                    Hot within:
                  </label>
                  <NumberBox
                    value={settings ? settings.hotDuration : 1}
                    min={1}
                    onValueChanged={({ value }) =>
                      onUpdateRaabtaScoreDuration("hot", value)
                    }
                    format={"#0 Days"}
                    disabled={!settings}
                  />
                </div>

                <div className="field-row">
                  <div
                    className="color-box fa-solid fa-mug-hot"
                    style={{ color: raabtaScoreColors.warm }}
                  ></div>
                  <label title="A contact is considered Warm if the last contated date is after the Hot duration and before the Warm duration ">
                    Warm within:
                  </label>
                  <NumberBox
                    value={settings ? settings.warmDuration : 2}
                    min={settings ? settings.hotDuration + 1 : 2}
                    onValueChanged={({ value }) =>
                      onUpdateRaabtaScoreDuration("warm", value)
                    }
                    format={"#0 Days"}
                    disabled={!settings}
                  />
                </div>

                <div className="field-row">
                  <div
                    className="color-box fa-solid fa-snowflake"
                    style={{ color: raabtaScoreColors.cold }}
                  ></div>
                  <label title="A contact is considered Cold if the last contacted date is out of Warm duration ">
                    Cold After:
                  </label>
                  <TextBox
                    value={`${settings ? settings.warmDuration : 0} Days`}
                    readOnly
                    hint="Cold duration is calculated based on Warm duration"
                  />
                </div>
              </div>
            </div>
          </Item>
          <Item title="OpenAI API Key" icon="key">
            <div className="item-container">
              <div className="item-card" style={{ width: 600 }}>
                <div className="item-title dx-icon-key"> OpenAI API Key</div>
                <TextBox
                  width="100%"
                  value={openAiKey ? openAiKey.key : ""}
                  readOnly={syncingApiKey}
                  buttons={[
                    {
                      location: "after",
                      name: "Show",
                      options: {
                        icon: showApiKey
                          ? "fa-solid fa-eye-slash"
                          : "fa-solid fa-eye",
                        text: showApiKey ? "Hide Key" : "Show Key",
                        type: "default",
                        onClick: handleToggleApiKey,
                        stylingMode: "contained",
                      },
                    },
                  ]}
                  onValueChanged={handleApiKeyChange}
                  placeholder="Enter OpenAI API Key"
                  mode={showApiKey ? "text" : "password"}
                />
              </div>
            </div>
          </Item>
        </TabPanel>
      </div>
      <div className="button-group">
        <Button
          className="mt-3 button"
          icon="email"
          text={gmail.syncing ? "Syncing Gmail" : "Sync Gmail"}
          type="default"
          stylingMode="contained"
          onClick={onSyncGmailClick}
          disabled={gmail.syncing}
        />
        <Button
          className="mt-3 button"
          icon="runner"
          text={gmail.syncing ? "Applying Rules" : "Apply Rules"}
          type="default"
          stylingMode="contained"
          onClick={onApplyRules}
          disabled={applyingRules}
        />
      </div>
    </div>
  );
};
