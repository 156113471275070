import { createSlice } from '@reduxjs/toolkit'

export interface GmailState {
  syncing: boolean,
}

const initialState: GmailState = {
  syncing: false,
};

export const gmailSlice = createSlice({
  name: 'gmail',
  initialState,
  reducers: {
    updateGmailSync: (state, action) => {
      state.syncing = action.payload.syncing
    },
  },
})

// Action creators are generated for each case reducer function
export const { updateGmailSync } = gmailSlice.actions

export default gmailSlice.reducer