import { configureStore } from '@reduxjs/toolkit';
import emailAddressesSlice from './reducer/emailAddressesSlice';
import tagsSlice from './reducer/tagsSlice';
import gmailSlice  from './reducer/gmailSlice';
import trustedEmailAddressesSlice from './reducer/trustedEmailAddressesSlice';
import settingsSlice from './reducer/settingsSlice';

export const store = configureStore({
  reducer: {
    emailAddresses: emailAddressesSlice,
    trustedEmailAddresses: trustedEmailAddressesSlice,
    tags: tagsSlice,
    gmail: gmailSlice,
    settings: settingsSlice,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch