import axios from 'axios';

export async function getEventsByCountry(country) {
  try {
    // Send request
    const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/getEventsByCountry/${country}`, { withCredentials: true });
    return {
      isOk: true,
      data: response.data.events,
    };
  } catch {
    return {
      isOk: false,
      message: 'Failed to fetch events',
    };
  }
}

export async function addEvent(eventData) {
  try {
    // Send request
    const { title, from, to, country } = eventData;
    const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/event`, {
      title, from, to, country,
    }, { withCredentials: true });
    return {
      isOk: true,
      data: response.data.event,
    };
  } catch {
    return {
      isOk: false,
      message: 'Failed to store event',
    };
  }
}
