import axios from 'axios';

export async function getTags() {
  try {
    // Send request
    const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/getTags`, { withCredentials: true });
    return {
      isOk: true,
      data: response.data.tags,
    };
  } catch {
    return {
      isOk: false,
      message: 'Failed to fetch tags',
    };
  }
}

export async function addTag(name) {
  try {
    // Send request
    const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/tag`, {
      name
    }, { withCredentials: true });
    return {
      isOk: true,
      data: response.data.tag,
    };
  } catch {
    return {
      isOk: false,
      message: 'Failed to store tag',
    };
  }
}

export async function updateEmailTags(emailId: number, tagIds: number[]) {
  try {
    // Send request
    const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/setTags`, {
      emailId,
      tagIds
    }, { withCredentials: true });
    return {
      isOk: true,
      data: response.data.emails,
    };
  } catch {
    return {
      isOk: false,
      message: 'Failed to assign tag to email',
    };
  }
}