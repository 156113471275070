import axios from 'axios';

export async function getPublicEventsByCalendarId(calendarId) {
  try {
    // Send request
    const calender = await axios.get(`https://www.googleapis.com/calendar/v3/calendars/${calendarId}%23holiday%40group.v.calendar.google.com/events?key=AIzaSyCxpvBGl7UMV9zlO1eXrm7H1AdVK1DyFN8`);
    return {
      isOk: true,
      data: calender.data.items,
    };
  } catch {
    return {
      isOk: false,
      message: 'Failed to fetch events',
    };
  }
}

export async function getPublicEventByEventId(calendarId, eventId) {
  try {
    // Send request
    const calender = await axios.get(`https://www.googleapis.com/calendar/v3/calendars/${calendarId}%23holiday%40group.v.calendar.google.com/events/${eventId}?key=AIzaSyCxpvBGl7UMV9zlO1eXrm7H1AdVK1DyFN8`);
    return {
      isOk: true,
      data: calender.data.items,
    };
  } catch {
    return {
      isOk: false,
      message: 'Failed to fetch events',
    };
  }
}