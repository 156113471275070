import React, { useEffect } from 'react';

export const AuthCallback = () => {
  useEffect(() => {
    // Extract the code parameter from the query string
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    // Redirect the API request to the backend server
    window.location.href = `${process.env.REACT_APP_SERVER_URL}/auth/callback?code=${code}`;
  }, []);

  return (
    <div className='d-flex justify-content-center'>
      <div>
        <h4>Redirecting...</h4>
      </div>
      
    </div>
  );
};
