import axios from "axios";

export async function requestInvite(email) {
  try {
    // Send request
    const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/invitationRequest`, {
      email,
    }, { withCredentials: true });

    if(response.data.message) {
      return {
        isOk: false,
        message: response.data.message,
      };
    }
    return {
      isOk: true,
      data: response.data.invite,
    };
  } catch (error) {
    return {
      isOk: false,
      message: 'Failed to send invitation request',
    };
  }
}