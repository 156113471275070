import axios from 'axios';

export async function getRules() {
  try {
    // Send request
    const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/ruleEngine`, { withCredentials: true });
    return {
      isOk: true,
      data: res.data.rules,
    };
  } catch {
    return {
      isOk: false,
      message: 'Failed to fetch rules',
    };
  }
}

export async function addRule(rule) {
  try {
    // Send request
    const { name, condition, value, tags } = rule;
    const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/ruleEngine`, {
      name, condition, value, tags
    }, { withCredentials: true });
    return {
      isOk: true,
      data: res.data.rule,
    };
  } catch {
    return {
      isOk: false,
      message: 'Failed to add rule',
    };
  }
}

export async function updateRule(rule) {
  try {
    const {id, name, condition, value, tags} = rule;
    const res = await axios.put(`${process.env.REACT_APP_SERVER_URL}/ruleEngine/${id}`, {
      name, condition, value, tags
    }, { withCredentials: true });
    return {
      isOk: true,
      data: res.data.noOfRows,
    };
  } catch {
    return {
      isOk: false,
      message: 'Failed to delete rule',
    };
  }
}

export async function deleteRule(id) {
  try {
    // Send request
    const res = await axios.delete(`${process.env.REACT_APP_SERVER_URL}/ruleEngine/${id}`, { withCredentials: true });
    return {
      isOk: true,
      data: res.data.noOfRows,
    };
  } catch {
    return {
      isOk: false,
      message: 'Failed to delete rule',
    };
  }
}

export async function applyRules() {
  try {
    // Send request
    const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/applyRules`, { withCredentials: true });
    return {
      isOk: true,
      data: res.data.message,
    };
  } catch {
    return {
      isOk: false,
      message: 'Failed to delete rule',
    };
  }
}