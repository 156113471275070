import axios from 'axios';

export async function getTrustedEmailAddresses() {
  try {
    // Send request
    const emails = await axios.get(`${process.env.REACT_APP_SERVER_URL}/trustedEmails`, { withCredentials: true });
    return {
      isOk: true,
      data: emails.data.trustedEmailAddresses,
    };
  } catch {
    return {
      isOk: false,
      message: 'Failed to fetch emails',
    };
  }
}

export async function addTrustedEmailAddress(emailAddress) {
  try {
    // Send request
    const emails = await axios.post(`${process.env.REACT_APP_SERVER_URL}/trustedEmail`, {
      emailAddress
    }, { withCredentials: true });
    return {
      isOk: true,
      data: emails.data.trustedEmailAddress,
    };
  } catch {
    return {
      isOk: false,
      message: 'Failed to add trusted email',
    };
  }
}

export async function deleteTrustedEmailAddress(id) {
  try {
    // Send request
    const emails = await axios.delete(`${process.env.REACT_APP_SERVER_URL}/trustedEmail/${id}`, { withCredentials: true });
    return {
      isOk: true,
      data: emails.data.noOfRows,
    };
  } catch {
    return {
      isOk: false,
      message: 'Failed to delete trusted email',
    };
  }
}