export const navigation = [
  {
    text: 'Emails',
    icon: 'fa-solid fa-envelope',
    path: '/email-addresses',
  },
  // {
  //   text: 'Emails',
  //   icon: 'email',
  //   path: '',
  //   items: [
  //     {
  //       text: 'Email Addresses',
  //       path: '/email-addresses',
  //     },
  //     {
  //       text: 'Send Email',
  //       path: '/email-client',
  //     },
  //   ]
  // },
  {
    text: 'Calendar',
    icon: 'fa-solid fa-calendar-days',
    path: '/calendar',
  },
  {
    text: 'Templates',
    icon: 'fa-solid fa-file-code',
    path: '/templates',
  },
  // {
  //   text: 'Templates',
  //   icon: 'fa-solid fa-file-code',
  //   path: '',
  //   items: [
  //     {
  //       text: 'Templates',
  //       path: '/templates',
  //     },
  //     {
  //       text: 'Template Editor',
  //       path: '/template-editor',
  //     },
  //   ]
  // },
  {
    text: 'Admin',
    icon: 'user',
    path: '/admin',
  },
];
