import React, { useEffect, useState } from "react";
import DataGrid, {
  Column,
  Editing,
  LoadPanel,
  Lookup,
  Pager,
  Paging,
  RequiredRule,
  Toolbar,
  Item
} from "devextreme-react/data-grid";
import { editingOptions, ruleConditions } from "./RuleManagerConstants";
import {
  getRules,
  addRule,
  updateRule,
  deleteRule,
} from "../../api/ruleEngine";
import { useSelector } from "react-redux";
import TagBoxComponentForTags from "./TagBoxComponentForTags";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

export const RuleManager = () => {
  const [rules, setRules] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const tags = useSelector((state: any) => state.tags);


  const setDefaultValue = (e) => {
    e.data.name = "";
    e.data.condition = "contains";
    e.data.value = "";
    e.data.tags = [];
  };

  useEffect(() => {
    getRules().then((res) => {
      if (res.isOk) {
        setRules(res.data);
        setLoading(false);
      }
    });
  }, []);

  const cellTemplate = (container, options) => {
    const noBreakSpace = "\u00A0";
    const text = (options.value || [])
      .map((element) => tags.find((tag) => tag.id === element).name)
      .join(", ");
    container.textContent = text || noBreakSpace;
    container.title = text;
  };

  const onRuleAdded = ({ data }) => {
    setLoading(true);
    addRule(data)
      .then(() => {
        getRules().then((res) => {
          if (res.isOk) {
            setRules(res.data);
            setLoading(false);
          }
        });
      })
      .catch(() => toast.error("Error occured while adding rule"));
  };

  const onRuleUpdated = ({ data }) => {
    setLoading(true);
    updateRule(data)
      .then(() => {
        getRules().then((res) => {
          if (res.isOk) {
            setRules(res.data);
            setLoading(false);
          }
        });
      })
      .catch(() => toast.error("Error occured while updating rule"));
  };

  const onRuleDeleted = ({ data }) => {
    deleteRule(data.id)
      .then()
      .catch(() => toast.error("Error occured while deleting rule"));
  };

  return (
    <DataGrid
      showBorders
      dataSource={rules}
      keyExpr="id"
      columnAutoWidth
      onRowInserted={onRuleAdded}
      onRowUpdated={onRuleUpdated}
      onInitNewRow={setDefaultValue}
      onRowRemoved={onRuleDeleted}
    >
      <Toolbar>
        <Item location="before">
          <div className="grid-header">{"Rules"}</div>
        </Item>
        <Item location="after" name="addRowButton" />
      </Toolbar>
      <LoadPanel enabled={loading} />
      <Paging enabled pageSize={5} />
      <Pager visible showInfo showNavigationButtons />
      <Editing
        mode={editingOptions.mode}
        newRowPosition={editingOptions.newRowPosition}
        confirmDelete={editingOptions.confirmDelete}
        useIcons={editingOptions.useIcons}
        allowAdding
        allowUpdating
        allowDeleting
      />
      <Column caption="Name" dataField="name" dataType="string">
        <RequiredRule />
      </Column>
      <Column caption="Condition" dataField="condition">
        <Lookup
          dataSource={ruleConditions}
          displayExpr="display"
          valueExpr="value"
        />
        <RequiredRule />
      </Column>
      <Column caption="Value" dataField="value" dataType="string">
        <RequiredRule />
      </Column>
      <Column
        caption="Tags"
        dataField="tags"
        width={200}
        allowSorting={false}
        editCellComponent={TagBoxComponentForTags}
        cellTemplate={cellTemplate}
      >
        <Lookup dataSource={tags} valueExpr="id" displayExpr="name" />
        <RequiredRule />
      </Column>
    </DataGrid>
  );
};
