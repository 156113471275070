export const editingOptions = {
  mode: 'row', 
  newRowPosition: 'last', 
  confirmDelete: false, 
  useIcons: true,
}

export const ruleConditions = [
  { display: "Contains", value: "contains" },
  { display: "Not Contains", value: "not_contains" },
  { display: "Equals", value: "equals" },
  { display: "Not Equals", value: "not_equals" },
  { display: "Starts With", value: "starts_with" },
  { display: "Ends With", value: "ends_with" }, 
  { display: "Matches", value: "matches" }, 
  { display: "Not Matches", value: "not_matches" }, 
]