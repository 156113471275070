import React, { useState, useEffect, useCallback, useRef } from "react";
import TagBox from "devextreme-react/tag-box";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  addEmailAddress,
  updateEmailAddressTags,
  updateEmailAddress as updateEmailAddressInRedux,
} from "../../redux/reducer/emailAddressesSlice";
import "./email-addresses.scss";
import { toast } from "react-toastify";
import DataGrid, {
  Sorting,
  Selection,
  Scrolling,
  SearchPanel,
  ColumnChooser,
  Column,
  Toolbar,
  Item,
  LoadPanel,
  Export,
  Button as GridButton,
  FilterRow,
  Editing,
} from "devextreme-react/data-grid";
import { addTag as addTagInRedux } from "../../redux/reducer/tagsSlice";
import Button from "devextreme-react/button";
import { EmailNewForm, QuickTagger } from "../../components";
import { addTag, updateEmailTags } from "../../api/tag";
import { ValueChangedEvent } from "devextreme/ui/text_box";
import { Popup, SpeedDialAction } from "devextreme-react";
import { raabtaScoreColors } from "../../shared/constants";
import { updateEmailAddress } from "../../api/email";
import { Template } from "../templates/template-dashboard";

const exportFormats = ["xlsx", "pdf"];

interface EmailAddressProps {
  forEmailSendingOnly?: boolean;
  title?: string;
  publicEvent?: any;
  emailTemplate?: Template | null;
}

export const EmailAddresses: React.FC<EmailAddressProps> = ({
  forEmailSendingOnly = false,
  title = "Email Addresses",
  publicEvent,
  emailTemplate,
}) => {
  const emailAddresses = useSelector((state: any) => state.emailAddresses);
  const tags = useSelector((state: any) => state.tags);
  const settings = useSelector((state: any) => state.settings);
  const [showNewEmailPopup, setShowNewEmailPopup] = useState(false);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState([]);
  const [showQuickTagger, setShowQuickTagger] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  //if user is coming from sign in page, show quick tagger after 3 seconds
  // useEffect(() => {
  //   setTimeout(() => {
  //     setShowQuickTagger(true);
  //   }, 3 * 1000);
  // }, []);

  const gridRef = useRef<DataGrid>(null);

  const changeNewEmailPopupVisibility = useCallback(() => {
    setShowNewEmailPopup(!showNewEmailPopup);
  }, [showNewEmailPopup]);

  const onAddEmailClick = useCallback(() => {
    setShowNewEmailPopup(true);
  }, []);

  const addEmail = (emailInfo) => {
    dispatch(addEmailAddress({ emailAddress: emailInfo }));
  };

  const onSendEmailClick = useCallback(() => {
    const tempEmailAddresses = gridRef.current?.instance
      .getSelectedRowsData()
      .map((data) => data.emailAddress);
    if (tempEmailAddresses?.length) {
      navigate("/email-client", {
        state: {
          toEmails: tempEmailAddresses,
          publicEvent: publicEvent,
          emailTemplate: emailTemplate,
        },
        replace: false,
      });
    } else {
      toast.warning("Select an email address first", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }, [publicEvent, emailTemplate]);

  const filterOutNoReplyEmails = () => {
    gridRef.current?.instance.filter((data) => {
      let isFiltered = true;
      data.tags.forEach((tag) => {
        if (tag.name === "no-reply" || tag.name === "hidden") {
          isFiltered = false;
        }
      });
      return isFiltered;
    });
  };

  const filterByTags = useCallback((e: ValueChangedEvent) => {
    const { value } = e;
    if (value.length) {
      gridRef.current?.instance.filter((data: any) => {
        const tagNames = data.tags.map((tag: any) => tag.name);
        let isFiltered = value.every((val: any) => {
          return tagNames.includes(val);
        });
        return isFiltered;
      });
      gridRef.current?.instance.selectAll();
    } else {
      filterOutNoReplyEmails();
      gridRef.current?.instance.clearSelection();
    }
    setStatus(value);
  }, []);

  const refresh = useCallback(() => {
    gridRef.current?.instance.refresh();
  }, []);

  const onTagsChanged = (email: any, tags: any, changedTag: any) => {
    if (changedTag) {
      const tagIds = tags.map((tag: any) => tag.id);
      const index = emailAddresses.findIndex(
        (emailAddress: any) => emailAddress.id === email.id
      );
      if (index !== -1) {
        dispatch(
          updateEmailAddressTags({
            index,
            tags,
          })
        );
      }
      updateEmailTags(email.id, tagIds).then((res) => {
        if (res.isOk) {
        } else {
          //promt error
        }
      });
    }
  };

  const labelChooserRender = ({ data }) => {
    return (
      <TagBox
        disabled={forEmailSendingOnly}
        value={data.tags}
        dataSource={tags}
        displayExpr="name"
        placeholder="Add Tags"
        searchEnabled
        hideSelectedItems //todo: fix hiding
        onValueChanged={(e: any) => {
          if (e.event) {
            if (e.value.length > e.previousValue.length) {
              onTagsChanged(
                data,
                e.value,
                e.value.filter(
                  (obj2) => !e.previousValue.some((obj1) => obj1.id === obj2.id)
                )[0]
              );
            } else if (e.value.length < e.previousValue.length) {
              onTagsChanged(
                data,
                e.value,
                e.previousValue.filter(
                  (obj2) => !e.value.some((obj1) => obj1.id === obj2.id)
                )[0]
              );
            }
          }
        }}
      />
    );
  };

  useEffect(() => {
    filterOutNoReplyEmails();
    setLoading(false);
  }, []);

  const isHidden = (tags) => {
    if (
      tags &&
      tags.length > 0 &&
      tags.some((tag: any) => tag.name.toLowerCase() === "hidden")
    ) {
      return true;
    }
    return false;
  };

  const hideContact = async (e: any) => {
    let hiddenTagIndex = tags.findIndex(
      (tag: any) => tag.name.toLowerCase() === "hidden"
    );
    let hiddenTag = null;
    if (hiddenTagIndex === -1) {
      const newTag = "hidden";
      const res = await addTag(newTag);
      if (res.isOk) {
        hiddenTag = res.data;
        dispatch(addTagInRedux({ tag: res.data }));
      } else {
        toast.error("Error while adding tag");
      }
    } else {
      hiddenTag = tags[hiddenTagIndex];
    }
    if (hiddenTag) {
      const emailIndex = emailAddresses.findIndex(
        (email: any) => email.id === e.row.data.id
      );
      if (emailIndex !== -1) {
        let tags = Object.values(emailAddresses[emailIndex].tags);
        tags.push(hiddenTag);
        await dispatch(updateEmailAddressTags({ index: emailIndex, tags }));
        updateEmailTags(
          emailAddresses[emailIndex].id,
          tags.map((tag: any) => tag.id)
        ).then((res) => {
          if (res.isOk) {
          } else {
            toast.error("Error hiding contact");
          }
        });
      }
    }
  };

  const unHideContact = (e: any) => {
    const emailIndex = emailAddresses.findIndex(
      (email: any) => email.id === e.row.data.id
    );
    if (emailIndex !== -1) {
      const tags = Object.values(emailAddresses[emailIndex].tags);
      const hiddenTag = tags.find(
        (tag: any) => tag.name.toLowerCase() === "hidden"
      );
      if (hiddenTag) {
        tags.splice(tags.indexOf(hiddenTag), 1);
        dispatch(
          updateEmailAddressTags({
            index: emailIndex,
            tags,
          })
        );
        updateEmailTags(
          emailAddresses[emailIndex].id,
          tags.map((tag: any) => tag.id)
        ).then((res) => {
          if (res.isOk) {
          } else {
            toast.error("Error unhiding contact");
          }
        });
      }
    }
  };

  const onRowUpdating = async (e: any) => {
    const index = emailAddresses.findIndex(
      (emailAddress: any) => emailAddress.id === e.key
    );
    if (index !== -1) {
      let updatedEmailAddress = {
        ...emailAddresses[index],
        ...e.newData,
      };
      const res = await updateEmailAddress(updatedEmailAddress);
      if (res.isOk) {
        dispatch(
          updateEmailAddressInRedux({
            index,
            emailAddress: updatedEmailAddress,
          })
        );
        toast.success("Email address updated successfully");
      } else {
        toast.error("Failed to update email address");
      }
    } else {
      toast.error("Error while updating email address");
    }
    e.cancel = true;
    gridRef.current?.instance.cancelEditData();
  };

  return (
    <div className="view crm-contact-list">
      <div className="view-wrapper view-wrapper-contact-list">
        <DataGrid
          className="grid"
          noDataText="Head over to Admin Page to sync with Gmail or manually add email addresses"
          height="100%"
          dataSource={emailAddresses}
          ref={gridRef}
          keyExpr="id"
          columnAutoWidth
          onRowUpdating={onRowUpdating}
        >
          <Editing mode="row" allowUpdating />
          <LoadPanel enabled={loading} />
          <SearchPanel visible placeholder="Email Search" />
          <ColumnChooser enabled />
          <Export enabled allowExportSelectedData formats={exportFormats} />
          <Selection
            selectAllMode="allPages"
            showCheckBoxesMode="always"
            mode="multiple"
          />
          <FilterRow visible />
          <Sorting mode="multiple" />
          <Scrolling mode="virtual" />
          <Toolbar>
            <Item location="before">
              <div className="grid-header">{title}</div>
            </Item>
            <Item location="before" locateInMenu="auto">
              <TagBox
                value={status}
                dataSource={tags.map((tag) => tag.name)}
                searchEnabled
                hideSelectedItems
                multiline={false}
                showSelectionControls
                placeholder="Apply Tag Filters"
                applyValueMode="useButtons"
                onValueChanged={(e: any) => {
                  if (e.event) filterByTags(e);
                }}
              />
            </Item>
            <Item location="after" locateInMenu="auto">
              <Button
                icon="plus"
                text="Add Email"
                type="default"
                stylingMode="contained"
                onClick={onAddEmailClick}
                disabled={forEmailSendingOnly}
              />
            </Item>
            <Item location="after" locateInMenu="auto">
              <Button
                icon="email"
                text="Send Email"
                type="default"
                stylingMode="contained"
                onClick={onSendEmailClick}
              />
            </Item>
            <Item
              location="after"
              locateInMenu="auto"
              showText="inMenu"
              widget="dxButton"
            >
              <Button
                icon="refresh"
                text="Refresh"
                stylingMode="text"
                onClick={refresh}
              />
              {/* <Item locateInMenu="auto" name="save" /> */}
            </Item>
            <Item name="columnChooserButton" locateInMenu="auto" />
            <Item name="searchPanel" locateInMenu="auto" visible />
          </Toolbar>
          <Column dataField="id" visible={false} showInColumnChooser={false} />
          <Column dataField="username" caption="Sender" sortOrder="asc" />
          <Column dataField="emailAddress" caption="Sender Email" />
          <Column
            dataField="isImportant"
            caption="Is Important"
            dataType="boolean"
          />
          <Column
            caption="Raabta Score"
            cellRender={(e: any) => {
              const lastContacted = new Date(e.data.lastContacted);
              const today = new Date();
              const diffTime = Math.abs(
                today.getTime() - lastContacted.getTime()
              );
              const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
              // display: 'flex', flexDirection: 'row', justifyContent: 'center',
              if (!settings) {
                return (
                  <div
                    className="fa-solid fa-snowflake"
                    style={{ color: raabtaScoreColors.cold }}
                  >
                    {" "}
                    Cold
                  </div>
                );
              } else if (diffDays < settings.hotDuration) {
                return (
                  <div
                    className="fa-solid fa-fire"
                    style={{ color: raabtaScoreColors.hot }}
                  >
                    {" "}
                    Hot
                  </div>
                );
              } else if (diffDays < settings.warmDuration) {
                return (
                  <div
                    className="fa-solid fa-mug-hot"
                    style={{ color: raabtaScoreColors.warm }}
                  >
                    Warm
                  </div>
                );
              } else {
                return (
                  <div
                    className="fa-solid fa-snowflake"
                    style={{ color: raabtaScoreColors.cold }}
                  >
                    {" "}
                    Cold
                  </div>
                );
              }
            }}
            allowSearch={false}
            allowSorting
            calculateSortValue={(row: any) => row.lastContacted}
          />
          <Column
            dataField="lastContacted"
            caption="Last Contacted"
            dataType="datetime"
            format="dd/MM/yyyy HH:mm"
            allowEditing={false}
            visible={false}
          />
          <Column
            caption="Tags"
            dataType="string"
            cellRender={labelChooserRender}
            minWidth={200}
          />
          <Column type="buttons" caption={"Actions"}>
            <GridButton
              hint="Unhide"
              text="Unhide"
              icon={"fa-solid fa-eye"}
              visible={(e: any) => isHidden(e.row.data.tags)}
              onClick={unHideContact}
            />
            <GridButton
              hint="Hide"
              text="Hide"
              icon={"fa-solid fa-eye-slash"}
              visible={(e: any) => !isHidden(e.row.data.tags)}
              onClick={hideContact}
            />
            <GridButton name="edit" hint="Edit" />
          </Column>
        </DataGrid>
        <SpeedDialAction
          icon="tags"
          label="Quick Tagger"
          hint="Add tags to untagged emails"
          visible={
            window.location.hash === "#/email-addresses" && !showQuickTagger
          }
          onClick={() => {
            setShowQuickTagger(true);
          }}
        />
        {showQuickTagger && (
          <Popup
            showTitle={true}
            title="Quick Tagger"
            visible={showQuickTagger}
            onHiding={() => setShowQuickTagger(false)}
            dragEnabled={false}
            hideOnOutsideClick={false}
            showCloseButton={true}
            container=".dx-viewport"
            height="40%"
          >
            <QuickTagger
              emailAddresses={emailAddresses}
              labelChooserRender={labelChooserRender}
            />
          </Popup>
        )}
        <EmailNewForm
          visible={showNewEmailPopup}
          changeVisibility={changeNewEmailPopupVisibility}
          addEmail={addEmail}
        />
      </div>
    </div>
  );
};
