
import React, { useState, useRef } from 'react';
import TagBox from "devextreme-react/tag-box";
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { Button, DateBox, TextBox } from 'devextreme-react';
import ValidationGroup from 'devextreme-react/validation-group';
import Form, { Item as FormItem, GroupItem, ColCountByScreen } from 'devextreme-react/form';
import { FormTextbox, FormPhotoUploader } from '..';
import { EmailRule } from 'devextreme-react/validator';
import { getSizeQualifier } from '../../utils/media-query';
import { useScreenSize } from './../../utils/media-query';
import { addEvent as addEventInDatabase } from '../../api/event';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { RequiredRule, ValidationRule } from 'devextreme-react/data-grid';

interface Event {
  title: string,
  to: Date,
  from: Date,
}

const newEvent: Event = {
  title: '',
  to: new Date(),
  from: new Date()
}


export const EventNewForm = ({visible, changeVisibility, addEvent, country}) => {
  const [newEventInfo, setNewEventInfo] = useState<Event>(newEvent);
  const validationGroup = useRef<ValidationGroup>(null);
  const { isXSmall } = useScreenSize();

  const updateField = (field: string) => (value) => {
    setNewEventInfo((prevState) => ({ ...prevState, ...{ [field]: value } }));
  };

  const onSaveClick = () => {
    if (!validationGroup.current?.instance.validate().isValid) return;
    changeVisibility();
    addEventInDatabase({...newEventInfo, country}).then((event) => {
      if (event.isOk) {
        addEvent(event.data)
        toast.success("Event Added Successfully", {
          position: 'top-center',
        })
      } else {
        toast.error("Failed to Add Event", {
          position: 'top-center',
        })
      }
    });
    setNewEventInfo(newEvent);
  }

  const onCancelClick = () => {
    changeVisibility();
    setNewEventInfo(newEvent);
  }

  
  return (
    <Popup
      title={`New Event (${country})`}
      visible={visible}
      fullScreen={isXSmall}
      width='480px'
      height='auto'
    >
      <ToolbarItem
        widget='dxButton'
        toolbar='bottom'
        location='after'
      >
        <Button
          text= 'Save'
          stylingMode= 'outlined'
          type= 'default'
          onClick={onSaveClick}
        />
      </ToolbarItem>
      <ToolbarItem
        widget='dxButton'
        toolbar='bottom'
        location='after'
      >
        <Button
          text='Cancel'
          stylingMode='text'
          type='default'
          onClick={onCancelClick}
        />
      </ToolbarItem>
    <ValidationGroup ref={validationGroup}>
      <Form
        className='plain-styled-form'
        screenByWidth={getSizeQualifier}
      >

        {/* <GroupItem>
          <ColCountByScreen xs={1} sm={2} md={2} lg={2} /> */}
          <FormItem>
            <FormTextbox
              label='Title'
              value={newEventInfo.title}
              isEditing={false}
              icon='font'
              onValueChange={updateField('title')}
            />
          </FormItem>
          {/* <FormItem>
            <TextBox 
              value={newEventInfo.country}
              disabled
            />
            <FormTextbox
              value={newEventInfo.country}
              onValueChange={updateField('emailAddress')}
              isEditing
              icon='add'
            >
            </FormTextbox>
          </FormItem> */}
        {/* </GroupItem> */}

        <GroupItem cssClass='contact-fields-group'>
          <ColCountByScreen xs={1} sm={2} md={2} lg={2} />
          <FormItem>
            <DateBox 
              label='From'
              value={newEventInfo.from}
              onValueChange={updateField('from')}
            />
          </FormItem>
          <FormItem>
            <DateBox 
              label='To'
              value={newEventInfo.to}
              onValueChange={updateField('to')}
            />
          </FormItem>
        </GroupItem>
      </Form>
    </ValidationGroup>
    </Popup>
  );
};
