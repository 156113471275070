import React from "react";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import { Avatar, ListItem } from "@mui/material";
import { TextBox } from "devextreme-react";
import "./ChipsBoard.css";

interface ChipsBoardProps {
  items: {
    id: string;
    label: string;
    count: number;
  }[];
  callback: (item: any) => void;
}

export const ChipsBoard: React.FC<ChipsBoardProps> = (props) => {
  const textBoxRef = React.useRef<TextBox>(null);
  return (
    <div className="chips-board">
      <Paper
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          p: 0.5,
          m: 0,
        }}
        component="ul"
      >
        {props.items.map((data) => {
          return (
            <Chip
              key={data.id}
              style={{ margin: 5 }}
              avatar={
                <Avatar>
                  {data.count > 999
                    ? `${Math.round(data.count / 1000)}K+`
                    : data.count}
                </Avatar>
              }
              label={data.label}
            />
          );
        })}
      </Paper>
      <Paper
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          listStyle: "none",
          mt: 1,
        }}
      >
        <TextBox
          ref={textBoxRef}
          placeholder="Add a tag"
          showClearButton={true}
          stylingMode="outlined"
          buttons={[
            {
              name: "clear",
            },
            {
              name: "add",
              location: "after",
              options: {
                icon: "plus",
                stylingMode: "text",
                onClick: () => {
                  if (textBoxRef && textBoxRef.current) {
                    const value = textBoxRef.current.instance.option("value");
                    props.callback(value);
                  }
                },
              },
            },
          ]}
        />
      </Paper>
    </div>
  );
};
