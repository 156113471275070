import React from "react";
import { authorize, login } from "../../api/auth";
import "./google-authorization.css";
import {
  MDBBtn,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBInput,
} from "mdb-react-ui-kit";
import { GoogleLogin } from "@react-oauth/google";
import { toast } from "react-toastify";

export const GoogleAuth = () => {
  const onAuthenticate = async () => {
    authorize().then((res) => {
      if (res.url) {
        window.location.replace(res.url);
      }
    });
  };

  return (
    <div className="container" style={{ alignContent: "center" }}>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: 'center' }}>
          <div className="card">
            <div className="card-image">
              <img
                src="https://i.pinimg.com/originals/47/48/c1/4748c1b58b6570fadd34bbce42373f50.jpg"
                className="card-img-top"
                alt="..."
                height={300}
              />
            </div>
          </div>
          <div className="card" style={{ alignContent: "center" }}>
            <div className="card-body">
              <h2 className="card-title" style={{fontWeight:'bolder'}}>Raabta</h2>
              <p className="card-text">
                Keep your connections warm!
              </p>
              <GoogleLogin
                onSuccess={(credentialResponse) => {
                  login(credentialResponse).then((res) => {
                    if (res.url) {
                      window.location.replace(res.url);
                    }
                  });
                }}
                onError={() => {
                  toast.error("Failed to login");
                }}
                size="large"
                shape="rectangular"
                text="continue_with"
              />
            </div>
          </div>
        </div>
      </div>
  );
};
