import { GoogleLogin } from "@react-oauth/google";
import data from "../data/footerSection.json";
import { login } from "../../../api/auth";
import { toast } from "react-toastify";
import { Button, TextBox } from "devextreme-react";
import { useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { verifyCaptcha } from "../../../api/captcha";
import { requestInvite } from "../../../api/invite";
import { Paper } from "@mui/material";

const Footer = () => {
  const emailBoxRef = useRef(null);
  const captchaRef = useRef<ReCAPTCHA>(null);
  const handleEarlyAccessRequest = async (e: any) => {
    //get captcha value
    const token = await (captchaRef.current as any).executeAsync();
    (captchaRef.current as any).reset();

    const response = await verifyCaptcha(token);
    if (!response.isOk) {
      toast.error(response.message);
      return;
    } else if (response.isOk && !response.data) {
      toast.error("Captcha is not valid");
      return;
    }
    //get email from emailBoxRef
    const email = (emailBoxRef.current as any).instance.option("value");
    //check if email is valid emailAddress using regex
    const emailRegex = new RegExp(
      "^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$"
    );
    if (emailRegex.test(email)) {
      const response = await requestInvite(email);
      if (response.isOk) {
        toast.success("Invitation request sent successfully");
      } else {
        toast.error(response.message);
      }
    } else {
      toast.error("Email Address is not valid");
    }
  };
  return (
    <footer>
      <div className="header-footer">
        <h3>{data.title}</h3>
        <h5>{data.description}</h5>
      </div>
      <Paper elevation={24} style={{ padding: "20px", width: 600 }}>
        <div className="signup-button">
          <TextBox
            ref={emailBoxRef}
            mode="email"
            placeholder="name@example.com"
            stylingMode="outlined"
            style={{ width: "300px" }}
          />
          <Button
            style={{ marginLeft: "30px" }}
            text="Request Early Access"
            type="default"
            onClick={handleEarlyAccessRequest}
          />
        </div>
      </Paper>
      <ReCAPTCHA
        className="g-recaptcha"
        ref={captchaRef}
        sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY as string}
        size="invisible"
      />
    </footer>
  );
};

export default Footer;